<template>
    <!-- eslint-disable vue/no-v-html -->
    <Modal
        v-if="selectedPharmacy"
        ref="modal"
        size="short"
        @leave="modalClosed"
        @click.prevent="close"
    >
        <template #content>
            <div class="pharmacy-map__new" @click.prevent="close">
                <svg
                    width="60"
                    height="60"
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_3081_723)">
                        <path
                            d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 6.51855C42.9684 6.51855 53.4778 17.0348 53.4778 30C53.4778 42.9653 42.9684 53.4778 30 53.4778C17.0315 53.4778 6.5222 42.9653 6.5222 30C6.52225 17.0348 17.0315 6.51855 30 6.51855ZM41.4953 15.7544L24.1077 33.1457L18.468 27.5061L12.9346 33.0359L18.5742 38.6755L24.1443 44.2456L29.6741 38.7122L47.0654 21.3245L41.4953 15.7544Z"
                            fill="#007F32"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_3081_723">
                            <rect width="60" height="60" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <h4>Wybrana apteka:</h4>
                <div v-html="pharmacyAddressOnly(selectedPharmacy)" />
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '../partials/Modal.vue'
import { pharmacyAddressOnly } from './addressFormatter.js'

export default {
    components: {
        Modal
    },
    data() {
        return {
            selectedPharmacy: null
        }
    },
    mounted() {
        const userPharmacyCookies = Cookies.get('userPharmacy')
        if (!userPharmacyCookies) {
            return
        }

        if (Cookies.get('pharmacySelected') === '') {
            this.selectedPharmacy = JSON.parse(userPharmacyCookies)
            Cookies.remove('pharmacySelected')
            this.$nextTick(() => {
                this.$refs.modal.OpenModal()
                this.$refs.modal.SetCloseFromBackdropActive(true)
            })
        }
    },
    created() {
        this.autoClose()
    },
    methods: {
        pharmacyAddressOnly(address) {
            return pharmacyAddressOnly(address)
        },
        modalClosed() {
            if (Cookies.get('addedToCart')) {
                var data = JSON.parse(Cookies.get('addedToCart'))
                window.$apteline.cart.Submit(
                    { action: data.action },
                    data.data,
                    data.button,
                    false,
                    data.list
                )
                Cookies.remove('addedToCart')
            }
        },
        close() {
            this.$refs.modal?.CloseModal()
        },
        autoClose() {
            setTimeout(() => {
                this.close()
            }, 5000)
        }
    }
}
</script>

<style lang="scss">
.pharmacy-map {
    &__new {
        text-align: center;
        margin: 40px auto 40px;
        font-size: 14px;
        font-weight: 400;
        .icon-checked {
            cursor: pointer;
        }
        svg {
            margin: 30px;
        }
        h3 {
            color: $sherpa-blue;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            margin: 15px;
        }
        h4 {
            font-size: 20px;
            color: #000;
            text-transform: none;
            text-align: center;
        }
    }
}
</style>
