var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.visible,
          expression: "props.visible"
        }
      ],
      staticClass: "loader-dots__container",
      class: { "loader-dots__container--full": _vm.props.fullContainer }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function(_h, _vm) {
    var _c = _vm._c
    return _c("div", { staticClass: "loader-dots" }, [
      _c("div", { staticClass: "dot dot1" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot dot2" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot dot3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }