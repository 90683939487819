<template>
    <div class="new-pharmacy">
        <Modal ref="newpharmacy" theme="bordered">
            <template #content @click="close">
                <div v-if="step == 'form'" id="newPharmacyForm">
                    <h2>Zaproponuj swoją aptekę</h2>
                    <p>
                        Jeśli nie udało Ci się znaleźć apteki w Twojej okolicy,
                        możesz wskazać nam jej dane. A my postaramy się zrobić,
                        co w naszej mocy, żebyś w przyszłości mógł dokonać
                        zamówienia dokładnie tam, gdzie chcesz.
                    </p>
                    <ValidationObserver
                        ref="newPharmacyForm"
                        tag="form"
                        @submit.prevent="submit()"
                    >
                        <div v-if="submitError" class="login-error">
                            {{ submitError.message }}
                        </div>
                        <div class="checkout-login__form checkout-form form">
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="eager"
                                rules="required"
                                tag="div"
                                class="form__row"
                                vid="f1_name"
                            >
                                <input
                                    id="proposition.name"
                                    v-model.trim="proposition.name"
                                    type="text"
                                    name="proposition.address"
                                    autocomplete="email"
                                    aria-required="true"
                                    placeholder="Nazwa proponowanej apteki"
                                    :class="{ 'is-failed': errors[0] }"
                                />
                                <span
                                    v-if="errors.length"
                                    class="validation-advice"
                                    role="alert"
                                >
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="eager"
                                rules="required"
                                tag="div"
                                class="form__row"
                                vid="f2_address"
                            >
                                <input
                                    id="proposition.address"
                                    v-model.trim="proposition.address"
                                    type="text"
                                    name="proposition.address"
                                    autocomplete="email"
                                    aria-required="true"
                                    placeholder="Ulica i numer"
                                    :class="{ 'is-failed': errors[0] }"
                                />
                                <span
                                    v-if="errors.length"
                                    class="validation-advice"
                                    role="alert"
                                >
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="eager"
                                rules="required"
                                tag="div"
                                class="form__row"
                                vid="f3_city"
                            >
                                <input
                                    id="proposition.city"
                                    v-model.trim="proposition.city"
                                    type="text"
                                    name="proposition.city"
                                    autocomplete="email"
                                    aria-required="true"
                                    placeholder="Miejscowość"
                                    :class="{ 'is-failed': errors[0] }"
                                />
                                <span
                                    v-if="errors.length"
                                    class="validation-advice"
                                    role="alert"
                                >
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="eager"
                                rules="required"
                                tag="div"
                                class="form__row"
                                vid="f4_postcode"
                            >
                                <input
                                    id="postcode"
                                    v-model.trim="proposition.postcode"
                                    type="text"
                                    name="proposition.postcode"
                                    aria-required="true"
                                    placeholder="Kod pocztowy"
                                    :class="{ 'is-failed': errors[0] }"
                                />
                                <span
                                    v-if="errors.length"
                                    class="validation-advice"
                                    role="alert"
                                >
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="eager"
                                rules="email"
                                tag="div"
                                class="form__row"
                                vid="f4_postcode"
                            >
                                <input
                                    id="clientEmail"
                                    v-model.trim="proposition.clientEmail"
                                    type="text"
                                    name="proposition.clientEmail"
                                    autocomplete="email"
                                    aria-required="true"
                                    placeholder="Adres e-mail"
                                    :class="{ 'is-failed': errors[0] }"
                                />
                                <span
                                    v-if="errors.length"
                                    class="validation-advice"
                                    role="alert"
                                >
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider
                                v-slot="{ errors }"
                                mode="eager"
                                tag="div"
                                class="form__row"
                                vid="f4_postcode"
                            >
                                <input
                                    id="clientEmail"
                                    v-model.trim="proposition.phone"
                                    type="text"
                                    name="proposition.phone"
                                    autocomplete="email"
                                    aria-required="true"
                                    placeholder="Numer telefonu"
                                    :class="{ 'is-failed': errors[0] }"
                                />
                                <span
                                    v-if="errors.length"
                                    class="validation-advice"
                                    role="alert"
                                >
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>
                        <div class="checkout-actions">
                            <button
                                type="submit"
                                class="btn btn--bg btn--loader btn--mobile"
                            >
                                Prześlij dane
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
                <div
                    v-if="step == 'sent'"
                    class="new-pharmacy__thanks"
                    @click="close"
                >
                    <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_3081_723)">
                            <path
                                d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 6.51855C42.9684 6.51855 53.4778 17.0348 53.4778 30C53.4778 42.9653 42.9684 53.4778 30 53.4778C17.0315 53.4778 6.5222 42.9653 6.5222 30C6.52225 17.0348 17.0315 6.51855 30 6.51855ZM41.4953 15.7544L24.1077 33.1457L18.468 27.5061L12.9346 33.0359L18.5742 38.6755L24.1443 44.2456L29.6741 38.7122L47.0654 21.3245L41.4953 15.7544Z"
                                fill="#007F32"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_3081_723">
                                <rect width="60" height="60" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h4>Dziękujemy za zgłoszenie</h4>
                    <p>Przekazaliśmy Twoją prośbę do odpowiedniego działu.</p>
                </div>
                <div
                    v-if="step === 'pharmacy_already_exist'"
                    class="new-pharmacy__already-exist"
                    @click="close"
                >
                    <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_3081_723)">
                            <path
                                d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 6.51855C42.9684 6.51855 53.4778 17.0348 53.4778 30C53.4778 42.9653 42.9684 53.4778 30 53.4778C17.0315 53.4778 6.5222 42.9653 6.5222 30C6.52225 17.0348 17.0315 6.51855 30 6.51855ZM41.4953 15.7544L24.1077 33.1457L18.468 27.5061L12.9346 33.0359L18.5742 38.6755L24.1443 44.2456L29.6741 38.7122L47.0654 21.3245L41.4953 15.7544Z"
                                fill="#007F32"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_3081_723">
                                <rect width="60" height="60" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h4>Twoja apteka już jest w Apteline</h4>
                    <div class="pharmacy-already-exist">
                        <p>
                            Dziękujemy za Twoje zgłoszenie. Informujemy że
                            apteka, którą proponujesz jest już w Apteline:
                        </p>
                        <div class="pharmacy-data">
                            <p class="pharmacy-data__name">
                                <strong>{{ pharmacyData.name }}</strong>
                            </p>
                            <p class="pharmacy-data__address">
                                <span
                                    >{{ pharmacyData.street_address }},
                                    {{ pharmacyData.city }}</span
                                >
                            </p>
                        </div>
                        <p>
                            Czy chcesz wybrać tę aptekę, by kontynuować
                            zamówienie?
                        </p>
                    </div>
                    <button
                        ref="chooseButton"
                        class="btn--bg"
                        tabindex="0"
                        @click.prevent="choosePharmacy(pharmacyData)"
                    >
                        Wybierz aptekę
                    </button>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from '../partials/Modal.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import icons from '@js/app/icons.js'
import { pharmacyAddressOnly } from './addressFormatter.js'
import axios from 'axios'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        Modal
    },
    props: {
        visible: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            step: 'form',
            modalVisible: true,
            proposition: {
                email: '',
                name: '',
                address: '',
                postcode: '',
                city: ''
            },
            submited: false,
            submitError: {
                message: ''
            },
            pharmacyData: {
                type: Object,
                default: () => {}
            }
        }
    },
    mounted() {
        this.setValidationRules()
        icons(this.$el)
    },
    methods: {
        openForm() {
            this.proposition.name = ''
            this.proposition.address = ''
            this.proposition.postcode = ''
            this.proposition.city = ''
            this.proposition.clientEmail = ''
            this.proposition.phone = ''
            this.step = 'form'
            this.modalVisible = true
            this.$refs.newpharmacy.OpenModal()
        },
        pharmacyAddressOnly(address) {
            return pharmacyAddressOnly(address)
        },
        setValidationRules() {
            extend('required', {
                ...required,
                message: 'To pole jest wymagane.'
            })
        },
        submit() {
            this.$refs.newPharmacyForm.validate().then((success) => {
                if (success) {
                    this.sendData()
                }
            })
        },
        sendData() {
            const form_key = this.$parent.$parent.getFormKey()
            var params = new URLSearchParams()
            params.append('form_key', form_key)
            params.append('name', this.proposition.name)
            params.append('address', this.proposition.address)
            params.append('postcode', this.proposition.postcode)
            params.append('city', this.proposition.city)
            params.append('clientEmail', this.proposition.clientEmail)
            params.append('phone', this.proposition.phone)
            axios
                .post(BASE_URL + 'storelocator/ajax/proposePharmacy', params)
                .then((apiResponse) => {
                    const status = apiResponse.status
                    if (status == 201) {
                        this.step = 'sent'
                    } else if (status == 302 || status == 200) {
                        this.step = 'form'
                        this.formError = 'error1'
                    } else if (
                        202 === status &&
                        'pharmacy_already_exist' === apiResponse.data.status
                    ) {
                        this.step = 'pharmacy_already_exist'
                        this.pharmacyData = apiResponse.data.pharmacy
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        },
        choosePharmacy(pharmacyData) {
            window.$apteline.pharmacy.ChoosePharmacy(pharmacyData)
        },
        close() {
            this.modalVisible = false
            this.$refs.newpharmacy.CloseModal()
        }
    }
}
</script>

<style lang="scss">
.checkout-billing__agreements {
    .input-group {
        label {
            a > span {
                text-decoration: underline;
            }
            a > span:first-child {
                display: none;
            }
            a + label {
                display: none;
            }
            & > span:last-child {
                display: none;
            }

            &.agreement-expanded {
                a > span:first-child {
                    display: inline-block;
                }
                a > span:last-child {
                    display: none;
                }
                & > span:last-child {
                    display: block;
                }
            }
        }
    }
}
.custom-modal-backdrop {
    @media #{$media-to-sm} {
        background-color: #fff !important;
    }
}

.modalPharmacyMap .new-pharmacy {
    .custom-modal {
        @media #{$media-to-sm} {
            overflow: revert;
            height: auto;

            .custom-modal-wrapper,
            .custom-modal-content {
                height: auto;
                position: static;
            }
        }

        &--bordered {
            min-height: inherit !important;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin: 10px auto !important;
            margin-top: 10px !important;
            border: 1px solid #eee;
            border-radius: 12px !important;
            max-width: 400px !important;
            @media #{$media-to-sm} {
                max-width: 320px !important;
            }
            .form__row {
                margin-bottom: 8px;

                input {
                    font-style: normal;
                    font-size: 14px;
                    max-height: 37px;
                    padding: 10px 8px;
                }
            }
            #newPharmacyForm {
                @media #{$media-to-sm} {
                    padding: 15px;
                }
                .btn,
                button {
                    border-radius: 24px;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: inherit;
                    max-height: 37px;
                    min-height: 36px;
                    height: 37px;
                    padding-top: 0;
                    padding-bottom: 0;
                    width: 100%;
                }
            }
            .custom-modal-close {
                @media #{$media-to-sm} {
                    z-index: +1;
                    position: absolute;
                    right: -15px;
                    top: -15px;
                }
            }
            @media #{$media-to-sm} {
                position: relative !important;
            }
        }
    }
}
.new-pharmacy {
    width: 100%;
    bottom: -34px;
    position: absolute;
    left: -1px;
    &__field {
        background: $sherpa-blue;
        font-size: 13px;
        color: #fff;
        height: 27px;
        border-radius: 8px;
        min-width: 264px;
        padding-top: 5px;
        padding-left: 9px;
        button {
            display: block;
            float: right;
            height: 21px;
            color: $sherpa-blue;
            border: none;
            border-radius: 8px;
            padding: 0 10px 0 !important;
            text-transform: none;
            font-size: 12px;
            min-height: inherit;
            font-weight: 600;
            background: #fff;
            position: relative;
            left: -26px;
            top: -2px;
            &:hover,
            &:focus {
                background: $blue-stone;
                color: #fff;
            }
        }
    }
    &__thanks {
        text-align: center;
        padding: 20px 5px 20px;
        h4 {
            font-size: 24px !important;
            margin: 24px auto 12px;
        }
        h2 {
            font-weight: 600;
            font-size: 24px;
            text-align: center;
        }
    }
    &__already-exist {
        text-align: center;

        .pharmacy-already-exist {
            text-align: left;

            p {
                font-size: 14px;
                font-weight: 400;
            }
            .pharmacy-data {
                margin: 5px;
                &__name {
                    font-weight: 600;
                    color: $sherpa-blue;
                }
                &__address {
                    font-size: 12px;
                }
            }
        }
        button {
            width: 70%;
            border-radius: 24px;
        }
    }
}
</style>
