var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pharmacyData
    ? _c("div", { staticClass: "pharmacy-info-tooltip" }, [
        _c("div", {
          staticClass: "pharmacy-info-tooltip__address",
          domProps: { innerHTML: _vm._s(_vm.formatAddress) }
        }),
        _vm._v(" "),
        _vm.currentLocation &&
        _vm.pharmacyData.latitude &&
        _vm.pharmacyData.longitude
          ? _c("div", [
              _c("div", { staticClass: "pharmacy-info-tooltip__nav" }, [
                _c("div", { staticClass: "pharmacy-info-tooltip__distance" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm
                          .getDistance(_vm.currentLocation, {
                            lat: _vm.pharmacyData.latitude,
                            lng: _vm.pharmacyData.longitude
                          })
                          .toFixed(2)
                      ) +
                      "\n                km\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "pharmacy-info-tooltip__navigate",
                    attrs: {
                      href:
                        "https://www.google.com/maps/dir/?api=1&destination=" +
                        _vm.pharmacyData.latitude +
                        "," +
                        _vm.pharmacyData.longitude,
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }
                  },
                  [_vm._v("\n                Sprawdź dojazd\n            ")]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.allowSelect
          ? _c("div", { staticClass: "pharmacy-info-tooltip__action" }, [
              _c(
                "button",
                {
                  ref: "chooseButton",
                  staticClass: "btn--bg",
                  attrs: { tabindex: "0" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.choosePharmacy(_vm.pharmacyData)
                    }
                  }
                },
                [_vm._v("\n            Wybierz\n        ")]
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }