<template>
    <div>
        <PharmacyMap />
        <SelectedPharmacyModal />
    </div>
</template>

<script>
import PharmacyMap from './PharmacyMap.vue'
import SelectedPharmacyModal from './SelectedPharmacyModal.vue'

export default {
    name: 'Pharmacy',
    components: {
        PharmacyMap,
        SelectedPharmacyModal
    }
}
</script>

<style scoped></style>
