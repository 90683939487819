<template>
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="pharmacyData" class="pharmacy-info-tooltip">
        <div class="pharmacy-info-tooltip__address" v-html="formatAddress" />
        <div
            v-if="
                currentLocation &&
                pharmacyData.latitude &&
                pharmacyData.longitude
            "
        >
            <div class="pharmacy-info-tooltip__nav">
                <div class="pharmacy-info-tooltip__distance">
                    {{
                        getDistance(currentLocation, {
                            lat: pharmacyData.latitude,
                            lng: pharmacyData.longitude
                        }).toFixed(2)
                    }}
                    km
                </div>

                <a
                    :href="`https://www.google.com/maps/dir/?api=1&destination=${pharmacyData.latitude},${pharmacyData.longitude}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="pharmacy-info-tooltip__navigate"
                >
                    Sprawdź dojazd
                </a>
            </div>
        </div>
        <div v-if="allowSelect" class="pharmacy-info-tooltip__action">
            <button
                ref="chooseButton"
                class="btn--bg"
                tabindex="0"
                @click.prevent="choosePharmacy(pharmacyData)"
            >
                Wybierz
            </button>
        </div>
    </div>
</template>

<script>
import { openingHours, pharmacyAddressOnly } from './addressFormatter'
import { calculateDistance } from '@js/utils/utils.js'

export default {
    props: {
        pharmacyData: {
            type: Object,
            default: () => {}
        },
        currentLocation: {
            type: Object,
            default: () => {}
        },
        allowSelect: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formatAddress() {
            return this.pharmacyTooltipAddress(this.pharmacyData)
        }
    },

    methods: {
        choosePharmacy(pharmacyData) {
            window.$apteline.pharmacy.ChoosePharmacy(pharmacyData)
        },
        getDistance(from, to) {
            return calculateDistance(from, to)
        },
        pharmacyTooltipAddress(data) {
            return `${pharmacyAddressOnly(data)}
                <h3>Godziny otwarcia:</h3>
                ${openingHours(data)}
                `
        }
    }
}
</script>

<style lang="scss">
$distanceCircleWidth: 10px !default;
$distanceCirclesMargin: 12px !default;

.pharmacy-info-tooltip {
    color: $gray-darker3;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    h3 {
        font-size: inherit;
        margin: 15px 0 5px;
        color: $gray-dark;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__address {
        flex-grow: 1;
    }

    &__nav {
        position: relative;
        padding-left: $distanceCirclesMargin;
        color: $gray-dark;

        @media #{$media-xs} {
            display: none;
        }

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 60%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $gray-dark;
        }
    }

    &__distance {
        font-weight: 700;
        color: inherit;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(
                #{-$distanceCirclesMargin} - #{$distanceCircleWidth / 2}
            );
            background-color: #fff;

            @include n-theme() {
                border: 1px solid theme(color-primary);
            }
        }
    }

    &__navigate {
        color: inherit;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(
                #{-$distanceCirclesMargin} - #{$distanceCircleWidth / 2}
            );

            @include n-theme() {
                border: 1px solid theme(color-primary);
                background-color: theme(color-primary);
            }
        }
    }

    &__action {
        text-align: center;
        margin-top: 25px;
        width: 100%;

        button {
            min-width: 195px !important;
            width: 175px;
            padding: 0 10px;
        }
    }
}
</style>
