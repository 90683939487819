<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="testo">
        <Modal
            ref="loginmodal"
            theme="bordered"
            class="pharmacyMapModal"
            @before-enter="modalBeforeEnter"
        >
            <template #content>
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="favorite && loggedin"
                    class="pharmacy-map__new"
                    @click="close"
                >
                    <svg
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_3081_723)">
                            <path
                                d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 6.51855C42.9684 6.51855 53.4778 17.0348 53.4778 30C53.4778 42.9653 42.9684 53.4778 30 53.4778C17.0315 53.4778 6.5222 42.9653 6.5222 30C6.52225 17.0348 17.0315 6.51855 30 6.51855ZM41.4953 15.7544L24.1077 33.1457L18.468 27.5061L12.9346 33.0359L18.5742 38.6755L24.1443 44.2456L29.6741 38.7122L47.0654 21.3245L41.4953 15.7544Z"
                                fill="#007F32"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_3081_723">
                                <rect width="60" height="60" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <h4>Dodano do ulubionych:</h4>
                    <div v-html="pharmacyAddressOnly(favorite)" />
                </div>
                <div v-if="!loggedin" id="newPharmacyForm">
                    <div class="row checkout-columns">
                        <div>
                            <h2 style="margin-bottom: 15px">
                                Zaloguj się, aby dodać aptekę do ulubionych
                            </h2>
                            <ValidationObserver
                                ref="login"
                                tag="form"
                                @submit.prevent="submit()"
                            >
                                <div v-if="loginError" class="login-error">
                                    {{ loginError.message }}
                                </div>
                                <div
                                    class="checkout-login__form checkout-form form"
                                >
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        mode="eager"
                                        rules="required-email|email"
                                        tag="div"
                                        class="form__row"
                                        vid="f3_email"
                                    >
                                        <input
                                            id="login.email"
                                            v-model.trim="
                                                customerData.login.email
                                            "
                                            type="email"
                                            name="login.email"
                                            autocomplete="email"
                                            aria-required="true"
                                            placeholder="Tu wpisz swój email"
                                            :class="{ 'is-failed': errors[0] }"
                                        />
                                        <span
                                            v-if="errors.length"
                                            class="validation-advice"
                                            role="alert"
                                        >
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        mode="eager"
                                        rules="required"
                                        tag="div"
                                        class="form__row"
                                        vid="f4_password"
                                    >
                                        <input
                                            id="login.customer_password"
                                            v-model="
                                                customerData.login
                                                    .customer_password
                                            "
                                            type="password"
                                            aria-required="true"
                                            autocomplete="new-password"
                                            name="login.customer_password"
                                            :class="{
                                                'is-failed': errors[0]
                                            }"
                                            placeholder="Tu wpisz swoje hasło."
                                        />
                                        <span
                                            v-if="errors.length"
                                            class="validation-advice"
                                            role="alert"
                                        >
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </div>
                                <div class="checkout-actions">
                                    <button
                                        type="submit"
                                        class="btn btn--bg btn--loader btn--mobile"
                                    >
                                        Zaloguj się
                                    </button>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                    <a class="login-link" @click="redirectToRegister">
                        Nie masz konta? Zarejestruj się.
                    </a>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from '../partials/Modal.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import icons from '@js/app/icons.js'
import { pharmacyAddressOnly } from './addressFormatter.js'
import axios from 'axios'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        Modal
    },
    props: {
        favorite: {
            type: Object,
            required: false
        },
        loggedin: {
            type: Boolean,
            required: false
        },
        visible: {
            type: Boolean,
            required: false
        },
        storelocatorId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            modalVisible: true,
            customerData: {
                login: {
                    email: '',
                    customer_password: ''
                }
            },
            loginError: {
                message: ''
            },
            backUrl: false
        }
    },
    mounted() {
        this.setValidationRules()
        this.backUrl = BASE_URL + 'customer/account/login'
        icons(this.$el)
    },
    methods: {
        modalBeforeEnter() {
            this.loginError.message = ''
            this.customerData.login.email = ''
            this.customerData.login.customer_password = ''
        },
        redirectToRegister() {
            window.location =
                BASE_URL +
                'customer/account/create' +
                '?storelocatorId=' +
                this.storelocatorId
        },
        showLogin() {
            this.modalVisible = true
        },
        pharmacyAddressOnly(address) {
            return pharmacyAddressOnly(address)
        },
        setValidationRules() {
            extend('required', {
                ...required,
                message: 'To pole jest wymagane.'
            })

            extend('required-email', {
                ...required,
                message: 'Podaj adres e-mail.'
            })

            extend('email', {
                ...email,
                message: 'Adres jest błędnie wpisany. Popraw adres e-mail.'
            })
        },
        submit() {
            this.$refs.login.validate().then((success) => {
                if (!success) {
                    const observer = this.$refs.login
                    const keys = [...Object.keys(observer.fields)].sort()
                    for (const key of keys) {
                        if (observer.fields[key].invalid) {
                            observer.refs[key].$el.scrollIntoView({
                                behavior: 'smooth'
                            })
                            return
                        }
                    }
                    return
                }
                this.sendLoginData()
            })
        },
        sendLoginData() {
            const form_key = this.$parent.$parent.getFormKey()
            var params = new URLSearchParams()
            params.append('form_key', form_key)
            params.append('context', 'pharmacymap')
            params.append('storelocatorId', this.storelocatorId)
            params.append('login[username]', this.customerData.login.email)
            params.append(
                'login[password]',
                this.customerData.login.customer_password
            )
            axios
                .post(BASE_URL + 'customer/account/loginPost', params)
                .then((apiResponse) => {
                    const status = apiResponse.status
                    if (status == 201) {
                        //zalogowany
                        this.$parent.$parent.getCustomerData()
                        this.$parent.$parent.refreshFavoritesAfterLogin()
                        this.closeModal
                    } else if (status == 302 || status == 200) {
                        this.loginError.message = 'Użytkownik juz zalogowany'
                        this.$props.loggedin = true
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 401) {
                        this.loginError.message =
                            'Niestety nie udało się zalogować. Spróbuj ponownie.'
                        this.$props.loggedin = false
                    } else {
                        console.error(error)
                    }
                })
        },
        close() {
            console.log('!!!!! close')
            this.modalVisible = false
            this.$refs.loginmodal.CloseModal()
        }
    }
}
</script>

<style lang="scss">
.login-link {
    display: block;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-top: 6px !important;
    text-decoration: underline;
    cursor: pointer;
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
#newPharmacyForm {
    h2 {
        text-align: center;
        margin-bottom: 6px;
    }
    input {
        padding-left: 8px;
        :placeholder-shown {
            padding-left: 40px !important;
        }
    }
}
.testo .pharmacyMapModal {
    .login-error {
        color: $red;
        font-size: 13px;
        font-weight: 600;
    }

    .custom-modal {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        max-width: 320px;

        @media #{$media-to-sm} {
            height: auto !important;
            max-width: 320px !important;
            min-height: inherit !important;
            overflow: revert;

            .custom-modal-wrapper,
            .custom-modal-content {
                height: auto;
                position: static;
            }
        }
        .custom-modal-close {
            @media #{$media-to-sm} {
                z-index: +1;
                position: absolute;
                right: -15px;
                top: -15px;
            }
        }

        .checkout-actions {
            text-align: center;
        }
    }
    .custom-modal-wrapper {
        .custom-modal-content {
            margin: 0 !important;
            padding: 0;

            .pharmacy-map__new,
            #newPharmacyForm {
                margin: 0px;
                padding: 64px 50px;
                max-width: 360px;
            }
        }
    }
}
</style>
