<template>
    <div v-if="!isHidden" class="pharmacy-map__filters">
        <LoaderDots :visible="!!isFiltersLoading" :full-container="true" />
        <span
            v-show="!isFiltersLoading"
            class="pharmacy-map__back"
            @click="back"
        ></span>
        <div
            v-for="(filter, index) in filters"
            :key="index"
            class="pharmacy-map__filter-item"
        >
            <div class="pharmacy-map__filter-item__title">
                {{ filter.label }}:

                <svg
                    v-if="filter.label === 'Apteki i punkty apteczne'"
                    ref="tooltip"
                    data-title="W punktach aptecznych możesz kupić wszystkie leki wydawane bez recepty oraz leki dostępne na receptę, jeśli spełniają kryteria określone w odpowiednim rozporządzeniu ministra zdrowia. Aby punkt apteczny mógł sprzedawać leki refundowane, musi zawrzeć umowę z Narodowym Funduszem Zdrowia."
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M8.00016 14.6668C11.6668 14.6668 14.6668 11.6668 14.6668 8.00016C14.6668 4.3335 11.6668 1.3335 8.00016 1.3335C4.3335 1.3335 1.3335 4.3335 1.3335 8.00016C1.3335 11.6668 4.3335 14.6668 8.00016 14.6668ZM8.20016 4.86016C8.3335 4.74016 8.4935 4.66683 8.66683 4.66683C8.84683 4.66683 9.00016 4.74016 9.14016 4.86016C9.26683 5.00016 9.3335 5.16016 9.3335 5.3335C9.3335 5.5135 9.26683 5.66683 9.14016 5.80683C9.00016 5.9335 8.84683 6.00016 8.66683 6.00016C8.4935 6.00016 8.3335 5.9335 8.20016 5.80683C8.0735 5.66683 8.00016 5.5135 8.00016 5.3335C8.00016 5.16016 8.0735 5.00016 8.20016 4.86016ZM6.5335 7.98016C6.5335 7.98016 7.98016 6.8335 8.50683 6.78683C9.00016 6.74683 8.90016 7.3135 8.8535 7.60683L8.84683 7.64683C8.7535 8.00016 8.64016 8.42683 8.52683 8.8335C8.2735 9.76016 8.02683 10.6668 8.08683 10.8335C8.1535 11.0602 8.56683 10.7735 8.86683 10.5735C8.90683 10.5468 8.94016 10.5202 8.9735 10.5002C8.9735 10.5002 9.02683 10.4468 9.08016 10.5202C9.0935 10.5402 9.10683 10.5602 9.12016 10.5735C9.18016 10.6668 9.2135 10.7002 9.1335 10.7535L9.10683 10.7668C8.96016 10.8668 8.3335 11.3068 8.08016 11.4668C7.80683 11.6468 6.76016 12.2468 6.92016 11.0802C7.06016 10.2602 7.24683 9.5535 7.3935 9.00016C7.66683 8.00016 7.78683 7.54683 7.1735 7.94016C6.92683 8.08683 6.78016 8.18016 6.6935 8.24016C6.62016 8.2935 6.6135 8.2935 6.56683 8.20683L6.54683 8.16683L6.5135 8.1135C6.46683 8.04683 6.46683 8.04016 6.5335 7.98016Z"
                        fill="#007F32"
                    />
                </svg>
            </div>
            <div class="pharmacy-map__filter-item__btns">
                <div
                    v-for="(item, itemIndex) in filter.items"
                    :key="itemIndex"
                    :ref="`filter${itemIndex}`"
                    :class="{
                        'pharmacy-map__filter-item__btn--act': item.is_active
                    }"
                    class="pharmacy-map__filter-item__btn"
                    @click="chooseFilter(itemIndex, item)"
                >
                    <span>{{ item.label }}</span>
                </div>
            </div>
        </div>
        <div
            v-show="!isFiltersLoading"
            class="pharmacy-map__filter-item__options"
        >
            <span class="pharmacy-map__filter-item__clear" @click="clear"
                >Wyczyść</span
            >
            <span
                :class="{ 'pharmacy-map__filter-item__apply_shake': disabled }"
                class="pharmacy-map__filter-item__apply"
                @click="apply"
                >Zastosuj zmiany >></span
            >
        </div>
    </div>
</template>

<script>
import LoaderDots from '../utils/LoaderDots.vue'
import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm.js'

export default {
    components: {
        LoaderDots
    },
    props: {
        isHidden: Boolean,
        filters: Object,
        isFiltersLoading: Boolean
    },
    data() {
        return {
            activeFilters: [],
            disabled: false
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(obj) {
                this.$nextTick(() => {
                    Object.keys(obj).forEach((key) => {
                        if (obj[key].label === 'Apteki i punkty apteczne') {
                            this.initTooltip()
                        }
                    })
                })
            }
        }
    },

    methods: {
        initTooltip() {
            new Tooltip(this.$refs.tooltip[0], {
                template:
                    '<div class="tooltip pharmacy-map-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
            })
        },
        back() {
            this.$emit('changeVisibility')
        },
        apply() {
            this.$emit('applyFilters', this.activeFilters)
            this.$emit('changeVisibility')
        },
        clear() {
            const elements = document.querySelectorAll(
                '.pharmacy-map__filter-item__btn--act'
            )

            elements.forEach((el) => {
                el.classList.remove('pharmacy-map__filter-item__btn--act')
            })

            this.activeFilters = []
            this.apply()
        },
        chooseFilter(index, item) {
            if (
                this.$refs['filter' + index][0].classList.contains(
                    'pharmacy-map__filter-item__btn--act'
                )
            ) {
                this.$refs['filter' + index][0].classList.remove(
                    'pharmacy-map__filter-item__btn--act'
                )

                this.activeFilters.splice(
                    this.activeFilters.indexOf(item.function),
                    1
                )
                return
            }

            this.$refs['filter' + index][0].classList.add(
                'pharmacy-map__filter-item__btn--act'
            )

            this.activeFilters.push(item.function)
            this.toggleShake()
        },
        toggleShake() {
            this.disabled = true
            setTimeout(() => {
                this.disabled = false
            }, 1500)
        }
    }
}
</script>

<style>
.pharmacy-map__filters {
    position: absolute;
    z-index: 452;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 13px;
    padding-left: 8px;
    height: 100%;

    @media #{$media-to-sm} {
        min-height: 280px;
        right: 15px;
        border-radius: 8px;
        border: 1px solid rgba(183, 189, 196, 0.5);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
}

.pharmacy-map-tooltip {
    z-index: 9999999999;
}

.pharmacy-map__filter-item {
    padding-top: 7px;
    padding-bottom: 1px;
    padding-right: 20px;
    max-width: 300px;

    &__title {
        color: $blue-dianne;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        width: 100%;
        padding-bottom: 8px;

        svg {
            position: relative;
            top: 4px;
            left: 4px;
        }
    }

    &__btns {
        display: flex;
        width: 100%;
    }

    &__btn {
        width: 50%;
        padding-left: 5px;

        &:nth-child(2n + 1) {
            padding-right: 5px;
            padding-left: 0px;
        }

        span {
            display: block;
            text-align: center;
            color: $blue-dianne;
            font-size: 11px;
            font-weight: 600;
            border-radius: 8px;
            border: 1px solid $blue-dianne;
            cursor: pointer;
            padding: 7px 0px;

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    color: #fff;
                    background: $sherpa-blue;
                }
            }
        }

        &--act {
            span {
                color: #fff;
                background: $sherpa-blue;
            }
        }
    }

    &__options {
        text-align: center;
        padding-top: 10px;
        max-width: 280px;

        span {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            display: inline-block;
            cursor: pointer;
        }
    }

    &__clear {
        margin-right: 10px;
    }

    &__apply {
        color: $sherpa-blue !important;
        font-weight: 600 !important;
        border: transparent solid 1px;
        border-radius: 8px;
        padding: 4px;
    }
    &__apply_shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        color: $sherpa-blue !important;
        border-color: $sherpa-blue;
    }
}

.pharmacy-map__back {
    display: block;
    width: 24px;
    height: 24px;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTAuODc1IDE5LjMwMDFMNC4yNzQ5OSAxMi43MDAxQzQuMTc0OTkgMTIuNjAwMSA0LjEwMzk4IDEyLjQ5MTcgNC4wNjE5OCAxMi4zNzUxQzQuMDE5OTggMTIuMjU4NCAzLjk5OTMyIDEyLjEzMzQgMy45OTk5OSAxMi4wMDAxQzMuOTk5OTkgMTEuODY2NyA0LjAyMDk5IDExLjc0MTcgNC4wNjI5OSAxMS42MjUxQzQuMTA0OTkgMTEuNTA4NCA0LjE3NTY1IDExLjQwMDEgNC4yNzQ5OSAxMS4zMDAxTDEwLjg3NSA0LjcwMDA2QzExLjA1ODMgNC41MTY3MyAxMS4yODc3IDQuNDIxMDYgMTEuNTYzIDQuNDEzMDZDMTEuODM4MyA0LjQwNTA2IDEyLjA3NTcgNC41MDA3MyAxMi4yNzUgNC43MDAwNkMxMi40NzUgNC44ODMzOSAxMi41NzkzIDUuMTEyNzMgMTIuNTg4IDUuMzg4MDZDMTIuNTk2NyA1LjY2MzM5IDEyLjUwMDcgNS45MDA3MyAxMi4zIDYuMTAwMDZMNy4zOTk5OSAxMS4wMDAxSDE4LjU3NUMxOC44NTgzIDExLjAwMDEgMTkuMDk2IDExLjA5NjEgMTkuMjg4IDExLjI4ODFDMTkuNDggMTEuNDgwMSAxOS41NzU3IDExLjcxNzQgMTkuNTc1IDEyLjAwMDFDMTkuNTc1IDEyLjI4MzQgMTkuNDc5MyAxMi41MjExIDE5LjI4OCAxMi43MTMxQzE5LjA5NjcgMTIuOTA1MSAxOC44NTkgMTMuMDAwNyAxOC41NzUgMTMuMDAwMUg3LjM5OTk5TDEyLjMgMTcuOTAwMUMxMi40ODMzIDE4LjA4MzQgMTIuNTc5MyAxOC4zMTY3IDEyLjU4OCAxOC42MDAxQzEyLjU5NjcgMTguODgzNCAxMi41MDA3IDE5LjExNjcgMTIuMyAxOS4zMDAxQzEyLjExNjcgMTkuNTAwMSAxMS44ODMzIDE5LjYwMDEgMTEuNiAxOS42MDAxQzExLjMxNjcgMTkuNjAwMSAxMS4wNzUgMTkuNTAwMSAxMC44NzUgMTkuMzAwMVoiIGZpbGw9IiMyNjQ2NTMiLz4KPC9zdmc+');
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
