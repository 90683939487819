<template>
    <div class="container__modalPharmacyMap">
        <Modal
            v-if="modalVisible"
            ref="modal"
            :size="cookieError ? 'short' : 'wide'"
            class="modalPharmacyMap"
            :class="!isFiltersHidden ? 'modalPharmacyMap--filters-act' : ''"
            @leave="modalClosed"
            @enter="modalReady"
            @before-enter="modalBeforeEnter"
        >
            <template #content>
                <div v-if="!cookieError" class="pharmacy-map row">
                    <LoaderDots :visible="!!isLoading" :full-container="true" />
                    <div
                        ref="form"
                        class="pharmacy-map__col"
                        :class="{
                            'pharmacy-map__col--choose': chooseMode,
                            'pharmacy-map__col--res': filteredPharmacies.length
                        }"
                    >
                        <div
                            v-if="
                                userPharmacy &&
                                !filteredPharmacies.length &&
                                !chooseMode
                            "
                            class="pharmacy-map__chosen-pharmacy"
                        >
                            <button
                                type="button"
                                class="close btn-close btn--clear pharmacy-map__chosen-pharmacy-close"
                                @click.prevent="
                                    ;(chooseMode = true),
                                        (chooseModeChanged = true)
                                "
                            >
                                <CloseIcon width="20" />
                            </button>
                            <h4>Wybrana apteka:</h4>
                            <div>
                                <PharmacyInfo
                                    :pharmacy-data="userPharmacy"
                                    :current-location="currentLocation"
                                    :allow-select="false"
                                    :allow-like="false"
                                />
                            </div>
                            <button
                                class="btn--bg pharmacy-map__chosen-pharmacy-btn"
                                @click.prevent="
                                    ;(chooseMode = true),
                                        (chooseModeChanged = true)
                                "
                            >
                                Zmień aptekę
                            </button>
                        </div>
                        <div v-if="chooseMode" class="pharmacy-map__header">
                            <div class="pharmacy-map__choose">
                                <div class="pharmacy-map-col-head">
                                    <div class="pharmacy-map-col-head__title">
                                        Znajdź aptekę:
                                    </div>
                                </div>
                                <!-- search -->
                                <div class="pharmacy-map__search">
                                    <div class="pharmacy-map__use-my-location">
                                        <button
                                            v-if="isGeolocationSupported"
                                            ref="geolocation"
                                            class="pharmacy-map__geolocate pharmacy-map-col-head__btn"
                                            :class="{
                                                'pharmacy-map__geolocate--disabled': geolocationManual
                                            }"
                                            @click.prevent="geolocate(true)"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 502.63 720.71"
                                                width="14.64"
                                                height="21"
                                            >
                                                <path
                                                    d="M251.31 0c138.8 0 251.32 112.52 251.32 251.31s-251.32 469.4-251.32 469.4S0 390.11 0 251.31 112.52 0 251.31 0zm0 360.35a109 109 0 10-109-109 109 109 0 00109 109z"
                                                    fill="#065360"
                                                />
                                            </svg>
                                            Użyj mojej lokalizacji
                                        </button>
                                    </div>
                                    <p>lub</p>
                                    <div class="pharmacy-map__form">
                                        <div
                                            class="input-group"
                                            :class="
                                                items.length && isInputActive
                                                    ? 'rollout'
                                                    : ''
                                            "
                                            data-placeholder="Wpisz kod pocztowy np. 12-345 lub ulicę np. Marszałkowska..."
                                        >
                                            <input
                                                id="suggester"
                                                ref="suggester"
                                                class="suggester"
                                                placeholder=" "
                                                :disabled="suggesterDisabled"
                                                @input="inputChange"
                                                @focus="inputFocus"
                                                @blur="inputBlur"
                                                @keyup.enter="mapFlyToFirst"
                                            />
                                            <button
                                                type="button"
                                                class="search-reset hidden"
                                            ></button>
                                            <div
                                                class="pharmacy-map__search-reset hidden"
                                                style="
                                                    width: 28px;
                                                    height: 28px;
                                                "
                                            >
                                                <svg
                                                    style="
                                                        width: 20px;
                                                        height: 20px;
                                                    "
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="5 5 25 25"
                                                >
                                                    <path
                                                        fill="#951"
                                                        d="M25.313 9.629l-7.438 7.438 7.438 7.438-1.875 1.875L16 18.942 8.562 26.38l-1.875-1.875 7.438-7.438-7.438-7.438 1.875-1.875L16 15.192l7.438-7.438z"
                                                    ></path>
                                                </svg>
                                            </div>
                                            <span class="input-group-btn">
                                                <button
                                                    type="submit"
                                                    title=""
                                                    class="btn btn--bg btn-default"
                                                    @click="mapFlyToFirst"
                                                >
                                                    <span>
                                                        <svg
                                                            width="19"
                                                            height="19"
                                                            viewBox="0 0 19 19"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M13.5 12H12.71L12.43 11.73C13.0549 11.0039 13.5117 10.1487 13.7675 9.22559C14.0234 8.30243 14.072 7.33413 13.91 6.38998C13.44 3.60998 11.12 1.38997 8.31997 1.04997C7.33559 0.925441 6.33576 1.02775 5.397 1.34906C4.45824 1.67038 3.60542 2.20219 2.90381 2.90381C2.20219 3.60542 1.67038 4.45824 1.34906 5.397C1.02775 6.33576 0.925441 7.33559 1.04997 8.31997C1.38997 11.12 3.60998 13.44 6.38998 13.91C7.33413 14.072 8.30243 14.0234 9.22559 13.7675C10.1487 13.5117 11.0039 13.0549 11.73 12.43L12 12.71V13.5L16.25 17.75C16.66 18.16 17.33 18.16 17.74 17.75C18.15 17.34 18.15 16.67 17.74 16.26L13.5 12ZM7.49997 12C5.00997 12 2.99997 9.98997 2.99997 7.49997C2.99997 5.00997 5.00997 2.99997 7.49997 2.99997C9.98997 2.99997 12 5.00997 12 7.49997C12 9.98997 9.98997 12 7.49997 12Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </span>
                                        </div>
                                        <div
                                            ref="results"
                                            class="suggester-results"
                                            @mouseenter="onResultsMouseEnter"
                                            @mouseleave="onResultsMouseLeave"
                                        >
                                            <ul
                                                v-if="items && isInputActive"
                                                class="suggester__list"
                                                :class="
                                                    items.length
                                                        ? 'rollout'
                                                        : 'hidden'
                                                "
                                            >
                                                <li
                                                    v-for="item in items"
                                                    ref="address-chosen"
                                                    :key="item.id"
                                                >
                                                    <span
                                                        v-if="item.type == 1"
                                                        v-html="iconWatch"
                                                    >
                                                    </span>
                                                    <span
                                                        v-if="item.type == 2"
                                                        v-html="iconGreyMarker"
                                                    >
                                                    </span>
                                                    <span
                                                        v-if="item.type == 3"
                                                        v-html="iconGreyMarker"
                                                    >
                                                    </span>
                                                    <span
                                                        v-if="item.type == 4"
                                                        v-html="iconHeart"
                                                    >
                                                    </span>
                                                    <span
                                                        v-if="item.type == 5"
                                                        v-html="iconWatch"
                                                    >
                                                    </span>
                                                    <a
                                                        :lat="item.lat"
                                                        :lon="item.lon"
                                                        @click.prevent="
                                                            getLocations(
                                                                item.lat,
                                                                item.lon,
                                                                item.name,
                                                                item.type,
                                                                item.city,
                                                                item.osmType,
                                                                item.boundingbox,
                                                                item.matched
                                                            )
                                                        "
                                                        v-html="item.name"
                                                    >
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- /search -->
                            </div>
                            <div class="pharmacy-map__list-container">
                                <div
                                    v-if="filteredPharmacies.length"
                                    ref="list"
                                    class="pharmacy-map__list"
                                >
                                    <h4>Najbliższe apteki</h4>
                                    <div
                                        v-for="pharmacy in filteredPharmacies"
                                        :key="pharmacy.storelocator_id"
                                        class="pharmacy-map__list-item"
                                    >
                                        <PharmacyInfo
                                            :pharmacy-data="pharmacy"
                                            :current-location="currentLocation"
                                            :search-results-type="
                                                searchResultsType
                                            "
                                            :allow-select="true"
                                            :allow-like="
                                                favorites.includes(
                                                    pharmacy.storelocator_id
                                                )
                                            "
                                            :class="
                                                'sid-' +
                                                pharmacy.storelocator_id
                                            "
                                        />
                                    </div>
                                    <div class="more">&nbsp;</div>
                                </div>
                            </div>
                            <PharmacyFiltersBtn
                                :is-filters-hidden="isFiltersHidden"
                                @toggle="toggleFiltersVisibility"
                            ></PharmacyFiltersBtn>
                        </div>
                        <PharmacyFiltersModal
                            :is-hidden="isFiltersHidden"
                            :filters="filters"
                            :is-filters-loading="isFiltersLoading"
                            @changeVisibility="
                                isFiltersHidden = !isFiltersHidden
                            "
                            @applyFilters="applyFilters"
                        ></PharmacyFiltersModal>
                        <!--                        <div class="new-pharmacy">-->
                        <!--                            <div class="new-pharmacy__field">-->
                        <!--                                Brakuje Twojej apteki?-->
                        <!--                                <button-->
                        <!--                                    ref="newpharmacybutton"-->
                        <!--                                    @click="newpharmacyClick"-->
                        <!--                                >-->
                        <!--                                    Zaproponuj aptekę-->
                        <!--                                </button>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                    <new-pharmacy ref="newpharmacy"></new-pharmacy>
                    <div
                        class="pharmacy-map__wrapper"
                        :class="
                            chooseMode ? 'pharmacy-map__wrapper--choose' : ''
                        "
                    >
                        <div
                            v-if="!isMapShow"
                            id="offlineMap"
                            class="pharmacy-map__placeholder"
                            style="
                                display: block;
                                background-image: url('/skin/frontend/neuca/default/images/mapka.jpg');
                            "
                        >
                            <button
                                v-if="!filteredPharmacies.length"
                                class="btn btn--bg pharmacy-map__switch-to-map"
                                :class="
                                    filteredPharmacies.length
                                        ? 'pharmacy-map__switch-to-map--list-act'
                                        : ''
                                "
                                :style="chooseMode ? 'margin-left: 147px' : ''"
                                @click="switchToMap"
                            >
                                Kliknij, aby załadować
                            </button>
                        </div>
                        <l-map
                            v-if="isMapShow"
                            id="map"
                            ref="map"
                            class="map-osm"
                            :options="mapOptions"
                            :center="center"
                            :min-zoom="5"
                            :max-zoom="17"
                            :zoom="zoom"
                            @ready="ready('map')"
                        >
                            <l-tile-layer :url="url" :attribution="attribution">
                            </l-tile-layer>
                            <l-control-zoom position="bottomright" />
                        </l-map>
                        <div
                            v-if="markerTooltipPharmacy"
                            id="pharmacyTooltipDiv"
                        >
                            <PharmacyInfoTooltip
                                :pharmacy-data="markerTooltipPharmacy"
                                :current-location="currentLocation"
                                :allow-select="true"
                            />
                            <button
                                draggable="false"
                                aria-label="Zamknij"
                                title="Zamknij"
                                type="button"
                                class="gm-ui-hover-effect"
                                @click="closePharmacyInfoTooltip"
                            >
                                <img
                                    src="https://apteline-cdn-static.azureedge.net/skin/frontend/neuca/default/icons/close.svg"
                                    width="22px"
                                    height="22px"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h4 class="custom-modal-title">Błąd wyboru apteki</h4>
                    <p class="custom-modal-error">
                        Wystąpił błąd przy wyborze apteki. Proszę odświeżyć
                        stronę i spróbować ponownie.
                    </p>
                </div>
                <login-modal
                    ref="favoriteconfirmation"
                    :favorite="favorite"
                    :loggedin="loggedin"
                    :storelocator-id="storelocatorId"
                ></login-modal>
            </template>
        </Modal>
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css"
        />
    </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios'
import { escape } from 'lodash'
import Modal from '../partials/Modal.vue'
import PharmacyInfo from './PharmacyInfo.vue'
import PharmacyInfoTooltip from './PharmacyInfoTooltip.vue'
import LoginModal from './LoginModal.vue'
import NewPharmacy from './NewPharmacy.vue'
import LoaderDots from '../utils/LoaderDots.vue'
import {
    EventBus,
    PHARMACY_UPDATED,
    CUSTOMER_DATA_UPDATED
} from '../utils/event-bus.js'
import { getCustomer } from '../Checkout/methods.js'
import {
    pharmacyAddressOnly,
    pharmacyAddressOnlyForSuggester
} from './addressFormatter.js'
import { LMap, LTileLayer, LControlZoom } from 'vue2-leaflet'
import { MarkerClusterGroup } from 'leaflet.markercluster'
import PharmacyFiltersModal from './PharmacyFiltersModal.vue'
import PharmacyFiltersBtn from './PharmacyFiltersBtn.vue'
import CloseIcon from '@icons/close.svg'
import { getSkinUrl } from '@js/utils/utils.js'

const POLAND_BOUNDS = {
    north: 55.116,
    east: 24.796,
    south: 48.618,
    west: 13.282
}
const NOMINATIM_API =
    'https://nominatim.openstreetmap.org/search.php?accept-language=pl&countrycodes=pl&format=jsonv2&q='
const ALLOWED_TYPES = [
    'postcode',
    'city',
    'street',
    'road',
    'town',
    'village',
    'state',
    'pharmacy'
]
const COOKIE_HISTORIC = 'userSearch'

export default {
    scopeName: 'pharmacy',
    components: {
        LoginModal,
        NewPharmacy,
        Modal,
        PharmacyInfo,
        PharmacyInfoTooltip,
        PharmacyFiltersModal,
        PharmacyFiltersBtn,
        LoaderDots,
        LMap,
        LTileLayer,
        LControlZoom,
        CloseIcon
    },
    pharmacyData: [], // not reactive for performance reasons,
    markers: [], // not reactive for performance reasons,
    data() {
        return {
            controller: new AbortController(),
            nominantRequestPending: false,
            markersLayer: null,
            fullPharmacyData: [],
            isMapShow: false,
            geoUrl: BASE_URL + 'storelocator/ajax/geolocalize',
            geoParams: {
                latitude: '',
                longitude: '',
                favorites: true,
                type: null,
                city: null,
                filters: ''
            },
            activeFilters: [],
            filters: {},
            isFiltersHidden: true,
            modalVisible: false,
            stateValue: '',
            cityValue: '',
            pharmacyValue: '',
            pharmacies: [],
            filteredPharmacies: [],
            pharmaciesLocationSorted: [],
            map: null,
            selectedPharmacy: null,
            currentLocation: null,
            isLoading: 0,
            isFiltersLoading: true,
            userPharmacy: false,
            chooseMode: true,
            chooseModeChanged: false,
            markerTooltipPharmacy: null,
            geolocationManual: false,
            invalidFields: [],
            cookieError: false,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> Contributors',
            zoom: 7,
            center: [52.2008737173322, 19.237405388153537],
            centerMobile: [55.54232350949031, 19.049604582908405],
            mapOptions: { zoomControl: true, scrollWheelZoom: true },
            clusterOptions: { disableClusteringAtZoom: 11 },
            items: [],
            inputText: '',
            inputTextStored: '',
            inputTextStatus: 0,
            customer: {},
            favorite: {},
            favorites: [],
            viewed: [],
            loggedin: false,
            iconWatch: `<svg fill="#888" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.42 489.42" xml:space="preserve" transform="rotate(90)">
                <g id="bgCarrier" stroke-width="0"/>
                <g id="tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="iconCarrier"> <g> <g>
                 <path d="M249,40.568c19.8,0,39.5,3.1,58.3,9.4c12.6,3.7,21.8-4.2,26-12.5c3.1-11.4-3.1-22.9-13.5-26 c-22.9-7.3-45.8-11.4-69.7-11.4c-11.4,0-20.8,8.3-20.8,19.8S237.6,40.568,249,40.568z"/>
                 <path d="M434.2,167.468c7.3,17.7,11.4,36.4,13.5,55.1c0,0,1.2,23.2,22.9,19.8c21.5-2.8,18.7-23.9,18.7-23.9 c-2.1-22.9-8.3-45.8-16.6-66.6c-5.2-10.4-16.6-15.6-27-11.4C435.2,145.668,430,157.068,434.2,167.468z"/>
                 <path d="M359.3,75.968c16.6,11.4,31.2,25,43.7,40.6c9.3,11.6,25,6.8,28.1,3.1c8.3-7.3,10.4-20.8,3.1-29.1 c-14.6-17.7-32.3-34.3-52-47.9c-9.4-6.2-21.8-4.2-28.1,5.2S349.9,69.668,359.3,75.968z"/> <path d="M134.6,72.768c16.6-10.4,35.4-18.7,54.1-23.9c11.4-3.1,17.7-14.6,14.6-25c-3.1-11.4-14.6-17.7-25-14.6 c-22.9,6.2-44.7,15.6-64.5,28.1c-9.4,6.2-12.5,18.7-6.2,28.1C111.7,71.768,120.5,77.968,134.6,72.768z"/>
                 <path d="M468.5,268.368c-11.4-3.1-21.8,4.2-23.9,15.6c-2.1,9.4-8.5,31.3-8.6,33.4c-27.5,71.5-93.5,121.8-169.3,129.9 c-74.6,7.8-147.2-25.9-189.3-86.5l38.5,8.5c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-81.1-17.7 c-5.2-1-21.4,0-25,15.6l-17.7,82.2c-2.1,10.4,4.2,21.8,15.6,23.9c12.7,1.3,21.8-6.2,25-16.6l6.2-28.2 c46.3,62.7,129.9,109.1,223.7,99c94.6-10.2,174.8-73.9,206-163.3c1-2.6,5.7-24.4,7.3-32.3 C487.3,280.868,480,270.468,468.5,268.368z"/>
                </svg>`,
            iconGreyMarker: `<svg v-if="item.type==3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502.63 720.71" width="14.64" height="21">
                <path
                    d="M251.31 0c138.8 0 251.32 112.52 251.32 251.31s-251.32 469.4-251.32 469.4S0 390.11 0 251.31 112.52 0 251.31 0zm0 360.35a109 109 0 10-109-109 109 109 0 00109 109z"
                    fill="#aaa"
                />
            </svg>`,
            iconLike: `serce`,
            iconRedMarker: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="60" width="40">
                  <g transform="translate(-814.59595,-274.38623)">
                    <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
                      <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:green;stroke:blue;"/>
                      <circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;fill:red;"/>
                    </g>
                  </g>
                </svg>`,
            iconHeart: `<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4297 0.640625C9.25254 0.640625 8.20781 1.10527 7.5 1.90391C6.79219 1.10527 5.74746 0.640625 4.57031 0.640625C3.54505 0.641865 2.56214 1.0497 1.83717 1.77467C1.1122 2.49964 0.704365 3.48255 0.703125 4.50781C0.703125 8.74648 6.90293 12.1332 7.1666 12.2756C7.26906 12.3308 7.38362 12.3597 7.5 12.3597C7.61638 12.3597 7.73094 12.3308 7.8334 12.2756C8.09707 12.1332 14.2969 8.74648 14.2969 4.50781C14.2956 3.48255 13.8878 2.49964 13.1628 1.77467C12.4379 1.0497 11.4549 0.641865 10.4297 0.640625ZM10.108 8.98203C9.29195 9.67451 8.4196 10.2978 7.5 10.8453C6.5804 10.2978 5.70805 9.67451 4.89199 8.98203C3.62227 7.89277 2.10938 6.23164 2.10938 4.50781C2.10938 3.85513 2.36865 3.22918 2.83017 2.76767C3.29168 2.30615 3.91763 2.04687 4.57031 2.04687C5.61328 2.04687 6.48633 2.59766 6.84902 3.48477C6.90181 3.61409 6.99192 3.72475 7.10786 3.80265C7.2238 3.88055 7.36032 3.92216 7.5 3.92216C7.63968 3.92216 7.7762 3.88055 7.89214 3.80265C8.00808 3.72475 8.09819 3.61409 8.15098 3.48477C8.51367 2.59766 9.38672 2.04687 10.4297 2.04687C11.0824 2.04687 11.7083 2.30615 12.1698 2.76767C12.6313 3.22918 12.8906 3.85513 12.8906 4.50781C12.8906 6.23164 11.3777 7.89277 10.108 8.98203Z"
                    fill="#888888" />
                </svg>`,
            storelocatorId: 0,
            apiAllResults: [],
            apiAllResultsPage: 0,
            apiAllResultsCopy: [],
            resultListElem: null,
            isInputActive: false,
            isMouseOverResults: false,
            searchResultsType: null
        }
    },
    computed: {
        PharmacyFiltersModal() {
            return PharmacyFiltersModal
        },
        isGeolocationSupported: () => window.navigator.geolocation,
        console: () => console,
        suggesterDisabled: function () {
            return this.fullPharmacyData.length == 0
        }
    },
    watch: {
        pharmacyValue(val) {
            if (val) {
                this.selectedPharmacy = this.$options.pharmacyData.find(
                    (el) => el.storelocator_id === val
                )

                this.$nextTick(() => {
                    this.$refs.pharmacyEl.setValue(
                        this.selectedPharmacy.storelocator_id
                    )
                })

                const pharmacyMarker = this.$options.markers.filter(
                    (marker) => marker.pharmacy.storelocator_id === val
                )?.[0]
                if (pharmacyMarker) {
                    this.openPharmacyTooltip(
                        pharmacyMarker,
                        this.selectedPharmacy
                    )
                    this.focusOnMap()
                }
            } else {
                this.selectedPharmacy = null
            }
        }
    },
    created() {
        this.getUserPharmacy()
        this.updatePharmacyInfo()
        this.getCustomerData()
    },
    methods: {
        scrollToTop() {
            if (this.$refs.list === undefined) {
                return
            }

            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.list.scrollTop = 0
                }, 100)
            })
        },
        newpharmacyClick() {
            this.$refs.newpharmacy.openForm()
        },
        applyFilters(activeFilters) {
            this.activeFilters = activeFilters
            this.geoParams.filters = this.activeFilters.join(',')
            this.refreshPharmacies()
        },
        switchToMap() {
            this.isMapShow = true
            this.loadMap()
            this.$nextTick(() => {
                this.addMarkers()
            })
        },
        toggleFiltersVisibility() {
            this.isFiltersHidden = !this.isFiltersHidden
            this.fetchFilters()
        },
        toTitleCase(str) {
            return str
                .toLowerCase()
                .split(' ')
                .map(function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1)
                })
                .join(' ')
        },
        closestPharmacy(position) {
            let pharmacyNear = {}
            if (position.lat && position.lng) {
                let shortest = 500
                this.$options.pharmacyData.forEach((pharmacy) => {
                    //simplified distance calculation
                    const distance =
                        Math.abs(pharmacy.longitude - position.lng) +
                        Math.abs(pharmacy.latitude - position.lat)
                    if (shortest > distance) {
                        shortest = distance
                        pharmacyNear = pharmacy
                    }
                })
                return pharmacyNear
            }
        },
        boldString(str, substr) {
            substr.replace(/,/, ' ')
            const arrWord = substr.toLowerCase().split(' ', 3)
            arrWord.forEach((word) => {
                if (word == ' ' || word == '') {
                    return
                }

                const position = str.toLowerCase().indexOf(word)
                const substrLength = word.length
                str =
                    str.substring(0, position) +
                    '<strong>' +
                    str.substring(position, position + substrLength) +
                    '</strong>' +
                    str.substring(position + substrLength)
            })

            return str
        },
        itemSelected(item) {
            this.item = item
        },
        setLabel(item) {
            return item.name
        },
        findMatchLocally(word) {
            const pharmacyArray = this.$options.pharmacyData
            let results = []
            word.replace(/,/, ' ')
            const arrWord = word.toLowerCase().split(' ', 3)
            arrWord.forEach((word) => {
                if (word == ' ' || word == '') {
                    return
                }

                const city = pharmacyArray.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    return el.city.toLowerCase().match(regex)
                })
                const street = pharmacyArray.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    return el.street_address.toLowerCase().match(regex)
                })
                const name = pharmacyArray.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    return el.name.toLowerCase().match(regex)
                })
                const postcode = pharmacyArray.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    return el.zipcode.match(regex)
                })

                let matched = city.concat(
                    street.filter((item) => city.indexOf(item) < 0)
                )

                matched = matched.concat(
                    name.filter((item) => matched.indexOf(item) < 0)
                )

                matched = matched.concat(
                    postcode.filter((item) => matched.indexOf(item) < 0)
                )

                results.push(matched)
            })

            let finalResults = []

            if (results.length == 0) {
                return finalResults
            }

            if (results.length < 2) {
                return results[0]
            }

            results[0].forEach((item) => {
                let match = true
                for (let step = 1; step < results.length; step++) {
                    const matchedResults = results[step].filter(
                        (i1) => i1.storelocator_id == item.storelocator_id
                    )
                    if (matchedResults.length == 0) {
                        match = false
                    }
                }

                if (match) {
                    finalResults.push(item)
                }
            })

            return finalResults
        },
        setFavorite(pharmacy) {
            this.favorite = pharmacy
            axios
                .post(BASE_URL + 'storelocator/ajax/addfavorite', {
                    storelocator_id: pharmacy.storelocator_id
                })
                .then((apiResponse) => {
                    const status = apiResponse.status
                    if (status == 201) {
                        //zalogowany
                        this.loggedin = true
                        this.favorites.push(pharmacy.storelocator_id)
                        this.markPharmacyFavourite(pharmacy.storelocator_id)
                    }
                    this.$refs.favoriteconfirmation.$children[0].OpenModal()
                    this.storelocatorId = 0
                    setTimeout(() => {
                        this.$refs.favoriteconfirmation.$children[0].CloseModal()
                    }, 5000)
                })
                .catch((e) => {
                    if (e.response.status == 401) {
                        //niezalogowany
                        this.loggedin = false
                        this.customer = {}
                        this.storelocatorId = pharmacy.storelocator_id
                        this.showLogin = true
                        this.$refs.favoriteconfirmation.$children[0].OpenModal()
                    }
                })
        },
        removeFavorite(pharmacy) {
            axios
                .post(BASE_URL + 'storelocator/ajax/removefavorite', {
                    storelocator_id: pharmacy.storelocator_id
                })
                .then((apiResponse) => {
                    if (apiResponse.status == 200) {
                        this.favorites = this.favorites.filter(function (id) {
                            return id !== pharmacy.storelocator_id
                        })
                    }
                })
        },
        markPharmacyFavourite(storelocator_id, mark = true) {
            this.$options.pharmacyData.forEach((pharmacy) => {
                if (pharmacy.storelocator_id == storelocator_id) {
                    pharmacy.favorite = mark
                }
            })

            this.filteredPharmacies.forEach((pharmacy) => {
                if (pharmacy.storelocator_id == storelocator_id) {
                    pharmacy.favorite = mark
                }
            })
        },
        getGeolocalize(
            latitude,
            longitude,
            type,
            favorites,
            withoutMove = false,
            city = null,
            searchResultsType = null
        ) {
            this.apiAllResults = []
            this.apiAllResultsPage = 0
            this.geoParams.latitude = latitude
            this.geoParams.longitude = longitude
            this.geoParams.favorites = favorites
            this.geoParams.type = type
            this.geoParams.city = city
            this.geoParams.searchResultsType = searchResultsType
            this.geoParams.searchText = this.inputText

            axios
                .get(this.geoUrl, {
                    params: this.geoParams
                })
                .then((apiResponse) => {
                    const results = apiResponse.data.results
                    const favorites = apiResponse.data.favorites
                    const viewed = apiResponse.data.viewed

                    this.apiAllResults = results
                    this.apiAllResultsCopy = this.apiAllResults

                    this.filteredPharmacies = []

                    if (results?.length > 0) {
                        this.processApiResults(favorites, viewed, true)
                    }

                    this.pharmacies = this.filteredPharmacies

                    if (!withoutMove) {
                        this.selectedPharmacy = this.pharmacies[0]
                        if (this.map) {
                            this.$nextTick(() => {
                                //this.addMarkers()
                                this.map.setZoom(13)
                                this.map.flyTo({
                                    lat: this.selectedPharmacy.latitude,
                                    lng: this.selectedPharmacy.longitude
                                })
                            })
                        }
                    }

                    const observer = new IntersectionObserver((entries) => {
                        if (entries[0].isIntersecting) {
                            this.filteredPharmacies = []
                            this.processApiResults(favorites, viewed)
                        }
                    })

                    setTimeout(() => {
                        const element = document.querySelector('.more')
                        if (element) {
                            observer.observe(document.querySelector('.more'))
                        }
                    }, 300)

                    this.$nextTick(() => {
                        this.scrollToTop()
                        this.$nextTick(() => {
                            this.scrollToTop()
                        })
                    })
                })
        },
        processApiResults(favorites, viewed, scrollToTop) {
            if (scrollToTop === undefined) {
                scrollToTop = false
            }
            this.filteredPharmacies = []
            this.pharmacies = []
            this.apiAllResults.every((el, index) => {
                this.fullPharmacyData.map((pharmacy) => {
                    if (pharmacy?.storelocator_id == el.storelocator_id) {
                        let pharmacyObj = Object.assign({}, pharmacy)
                        pharmacyObj.distance = el.distance
                        pharmacyObj.favorite = favorites?.includes(
                            pharmacy?.storelocator_id
                        )
                        pharmacyObj.viewed = viewed?.includes(
                            pharmacy?.storelocator_id
                        )
                        this.filteredPharmacies.push(pharmacyObj)
                    }
                })

                if (index >= 10 * (this.apiAllResultsPage + 1)) {
                    return false
                }

                return true
            })

            if (scrollToTop) {
                this.scrollToTop()
            }

            this.apiAllResultsPage = this.apiAllResultsPage + 1
        },
        getFavorites() {
            axios
                .get(BASE_URL + 'storelocator/ajax/getFavorites')
                .then((apiResponse) => {
                    const favorites = apiResponse.data.favorites
                    const viewed = apiResponse.data.viewed
                    this.favorites = favorites
                    this.viewed = viewed
                    this.favorites.forEach((el) => {
                        this.markPharmacyFavourite(el.storelocator_id, true)
                    })
                })
        },

        redIcon() {
            return new L.Icon({
                iconUrl:
                    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                shadowUrl:
                    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
            })
        },
        getIcon() {
            return L.divIcon({
                className: 'my-custom-pin',
                html: this.iconRedMarker
            })
        },
        addRedMarker(lat, lon, label) {
            const marker = L.marker([lat, lon], {
                icon: this.redIcon(),
                title: label
            })
            this.map?.addLayer(marker)
        },
        getLocations(
            latitude,
            longitude,
            name,
            type,
            city = null,
            osmType = null,
            boundingbox = null,
            searchResultsType = null
        ) {
            let cookieHistoric = []
            if (Cookies.get(COOKIE_HISTORIC)) {
                cookieHistoric = JSON.parse(Cookies.get(COOKIE_HISTORIC))
            }
            cookieHistoric.push({
                name: name
                    .replaceAll('<strong>', '')
                    .replaceAll('</strong>', ''),
                lat: latitude,
                lon: longitude,
                type: type,
                city: city,
                osmType: osmType,
                boundingbox: boundingbox
            })
            if (cookieHistoric.length > 4) {
                cookieHistoric.shift()
            }

            var now = new Date()
            var expireYears = 10
            var expireDate = new Date(
                now.getFullYear() + expireYears,
                now.getMonth(),
                now.getDate()
            )

            Cookies.set(COOKIE_HISTORIC, JSON.stringify(cookieHistoric), {
                expires: expireDate
            })

            if (this.map && searchResultsType != 'name') {
                if (boundingbox != undefined) {
                    this.map.flyToBounds([
                        [boundingbox[0], boundingbox[2]],
                        [boundingbox[1], boundingbox[3]]
                    ])
                } else {
                    let zoom = 12
                    if (type == 4 || osmType == 'neucaPharmacy') {
                        zoom = 15
                    }
                    this.map.flyTo([latitude, longitude], zoom)
                }
            } else if (this.map && searchResultsType == 'name') {
                this.map.flyTo(this.center, 7)
            }

            this.getGeolocalize(
                latitude,
                longitude,
                type,
                true,
                true,
                city,
                searchResultsType
            )
            this.items = []
        },
        onResultsMouseEnter() {
            this.isMouseOverResults = true
        },
        onResultsMouseLeave() {
            this.$refs.suggester.focus()
            this.isMouseOverResults = false
        },
        inputBlur() {
            if (this.isMouseOverResults) {
                return
            }
            setTimeout(() => {
                this.isInputActive = false
            }, 200)
        },
        inputFocus() {
            this.isInputActive = true
            if (this.inputText.length > 0) {
                return
            }

            if (this.inputText.length == 0 && this.selectedPharmacy) {
                const el = this.selectedPharmacy
                this.items.push({
                    id: el.storelocator_id,
                    type: 1,
                    name: el.name,
                    lat: el.lat,
                    lon: el.lon
                })
            }

            let historic = []
            if (Cookies.get(COOKIE_HISTORIC)) {
                historic = JSON.parse(Cookies.get(COOKIE_HISTORIC))
            }

            this.favorites?.forEach((el) => {
                const pharmacy = this.$options.pharmacyData.find((item) => {
                    return item.storelocator_id == el
                })

                let name = pharmacyAddressOnlyForSuggester(pharmacy)

                const elemExists = this.items.find((item) => {
                    return item.name == name
                })

                if (pharmacy && !elemExists) {
                    this.items.push({
                        type: 4,
                        lat: pharmacy.latitude,
                        lon: pharmacy.longitude,
                        name: name
                    })
                }
            })

            historic?.forEach((el) => {
                const historicExists = this.items.find((item) => {
                    return item.name == el.name
                })

                if (historicExists == undefined) {
                    this.items.push({
                        type: el.type != 3 ? 1 : 5,
                        lat: el.lat,
                        lon: el.lon,
                        name: el.name,
                        city: el.city,
                        osmType: el.osmType,
                        boundingbox: el.boundingbox
                    })
                }
            })
        },
        inputChange() {
            this.inputText = this.$refs.suggester.value
            if (this.inputTextStored === '') {
                this.inputTextStatus = 0
                this.suggestionsOnChange(this.inputText)
            } else {
                this.inputTextStored = this.inputText
                this.inputTextStatus = 1
            }
        },
        suggestionsOnChange(word /*, shouldFlyToMap = false*/) {
            const stringLength = this.$refs.suggester.value.length
            this.items = []

            if (stringLength > 1) {
                this.isLoading = 0
                this.findResultsInPharmacies(word)

                if (this.items.length == 0) {
                    try {
                        if (this.controller && this.nominantRequestPending) {
                            this.controller.abort()
                            this.controller = new AbortController()
                        }

                        this.nominantRequestPending = true
                        axios
                            .get(NOMINATIM_API + word, {
                                signal: this.controller.signal
                            })
                            .then((response) => {
                                this.items = []
                                response.data.forEach((el) => {
                                    this.processNominantResults(el, word)
                                })
                                this.nominantRequestPending = false
                                this.findResultsInPharmacies(word)

                                this.isLoading = 0

                                if (shouldFlyToMap && this.items.length > 1) {
                                    this.mapFlyToFirst()
                                }
                            })
                            .catch(function (error) {
                                this.isLoading = 0
                                console.error('!!!!!!', error)
                            })
                    } catch (err) {
                        this.isLoading = 0
                        console.error(err)
                        alert('Przepraszamy, wystąpił bład w wyszukiwaniu.')
                    }
                }
            }

            if (this.inputTextStatus > 0) {
                this.$nextTick(() => {
                    this.suggestionsOnChange(this.$refs.suggester.value)
                    this.inputTextStatus = 0
                })
            }
        },
        processNominantResults(el, word) {
            const allowedResultItem =
                ALLOWED_TYPES.includes(el.addresstype) || el.type == 'pharmacy'

            if (allowedResultItem && typeof el.display_name == 'string') {
                this.items.push({
                    id: el.place_id,
                    type: 3,
                    lat: el.lat,
                    lon: el.lon,
                    name: this.boldString(el.display_name, word),
                    city:
                        el.addresstype == 'city'
                            ? el.name
                            : el.type == 'pharmacy'
                            ? el.name
                            : '',
                    boundingbox: el.boundingbox,
                    state: el.state,
                    osmType: el.addresstype
                })
            }
        },
        mapFlyToFirst() {
            if (this.items.length > 0) {
                const firstResult = this.items[0]
                this.getLocations(
                    firstResult.lat,
                    firstResult.lon,
                    firstResult.name,
                    firstResult.type,
                    firstResult.city,
                    firstResult.osmType,
                    firstResult.boundingbox,
                    firstResult.matched
                )

                return
            }

            if (
                this.items.length == 0 &&
                this.$refs.suggester.value.length > 1
            ) {
                ++this.isLoading
                this.suggestionsOnChange(this.inputText, true)
            }

            // this.refreshPharmacies()
        },
        getSkinUrl(path) {
            return getSkinUrl(path)
        },
        findResultsInPharmacies(word) {
            const matchedPharmacies = this.findMatchLocally(word)

            word.replace(/,/, ' ')
            const arrWord = word.toLowerCase().split(' ', 3)

            arrWord.forEach((word) => {
                matchedPharmacies.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    const matched = el.city.toLowerCase().match(regex)
                    el.matchedCity = matched != null

                    return matched
                })
                matchedPharmacies.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    const matched = el.street_address.toLowerCase().match(regex)
                    el.matchedStreet = matched != null

                    return matched
                })
                matchedPharmacies.filter((el) => {
                    const regex = new RegExp(word, 'gi')
                    const matched = el.name.toLowerCase().match(regex)
                    el.matchedName = matched != null

                    return matched
                })
            })

            matchedPharmacies.forEach((el) => {
                let name =
                    el.zipcode +
                    ' ' +
                    el.city +
                    ', ' +
                    el.street_address +
                    ', ' +
                    el.name
                let indexOfPharmacy = this.items.findIndex((elem) => {
                    return elem.id == el.storelocator_id
                })

                let matched = null

                if (el.matchedCity) {
                    matched = 'city'
                }

                if (el.matchedStreet) {
                    matched = 'citystreet'
                }

                if (el.matchedName) {
                    matched = 'name'
                }

                if (indexOfPharmacy == -1) {
                    this.items.push({
                        id: el.storelocator_id,
                        type: 2,
                        lat: el.latitude,
                        lon: el.longitude,
                        name: this.boldString(name, word),
                        osmType: 'neucaPharmacy',
                        city: matched == 'city' ? el.city : null,
                        matched: matched
                    })
                }
            })
        },
        suggesterSubmit() {
            let inputText = this.$refs.suggester.value
            this.suggestionsOnChange(inputText)
            if (this.map) {
                this.$nextTick(() => {
                    this.addMarkers()
                })
            }
        },
        modalBeforeEnter() {
            this.getPharmacies()
        },
        modalReady() {
            this.getUserPharmacy()
            if (window?.GoogleAnalyticsUniversal) {
                window.GoogleAnalyticsUniversal.prototype.pharmacyEvent(
                    'Pharmacy popup',
                    this.userPharmacy ? this.userPharmacy.warehouse_id : null
                )
            }
        },

        modalClosed() {
            this.modalVisible = false
            this.$nextTick(() => {
                // reset component data
                Object.assign(this.$data, this.$options.data.apply(this))
                this.$options.pharmacyData = []
                this.$options.markers = []
            })
        },
        click() {
            // console.log('clusterclick', e)
        },
        ready() {
            // console.log('ready', e)
        },
        initMap() {
            --this.isLoading

            this.center = this.checkIsMobile() ? this.centerMobile : this.center

            this.$nextTick(() => {
                this.map = this.$refs.map.mapObject
                this.map.invalidateSize() //prevent not drawing all tiles
                if (window.innerWidth > 600 && window.innerWidth < 1200) {
                    this.zoom = 6
                }
                if (window.innerWidth <= 600) {
                    this.zoom = 5
                }
            })

            this.getUserPharmacy()
        },
        toggleMapBoundRestriction(isRestricted) {
            const restriction = {
                latLngBounds: POLAND_BOUNDS,
                strictBounds: false
            }
            this.map.setOptions({
                restriction: isRestricted ? restriction : null
            })
        },
        getPharmacies() {
            axios
                .get(BASE_URL + 'storelocator/ajax/pharmacies?type=map')
                .then((response) => {
                    this.$options.pharmacyData = response.data.pharmacies
                    this.fullPharmacyData = this.$options.pharmacyData
                    if (this.map) {
                        this.addMarkers()
                    }
                    this.getFavorites()
                })
        },
        async fetchFilters() {
            this.markerTooltipPharmacy = null
            this.isFiltersLoading = true
            this.geoParams.latitude = this.geoParams.latitude
                ? this.geoParams.latitude
                : this.center[0]
            this.geoParams.longitude = this.geoParams.longitude
                ? this.geoParams.longitude
                : this.center[1]
            try {
                const response = await axios.get(this.geoUrl, {
                    params: this.geoParams
                })
                if (
                    response.data &&
                    response.data.quick_filters !== undefined
                ) {
                    this.filters = response.data.quick_filters
                    this.isFiltersLoading = false
                } else {
                    console.error(
                        'Błąd pobierania filtrów, quick_filters nie istnieje: ',
                        error
                    )
                }
            } catch (error) {
                console.error('Błąd pobierania filtrów: ', error)
            }
        },
        refreshPharmacies() {
            ++this.isLoading
            this.markerTooltipPharmacy = null
            this.$options.pharmacyData = this.fullPharmacyData
            this.apiAllResults = this.apiAllResultsCopy
            this.$options.markers = []
            this.geoParams.latitude = this.geoParams.latitude
                ? this.geoParams.latitude
                : this.center[0]
            this.geoParams.longitude = this.geoParams.longitude
                ? this.geoParams.longitude
                : this.center[0]
            axios
                .get(this.geoUrl, {
                    params: this.geoParams
                })
                .then((response) => {
                    const favorites = response.data.favorites
                    const viewed = response.data.viewed

                    this.apiAllResults = response.data.results
                    if (this.activeFilters.length > 0) {
                        this.$options.pharmacyData = this.fullPharmacyData.filter(
                            (full) =>
                                response.data.results.some(
                                    (filtered) =>
                                        full.storelocator_id ===
                                        filtered.storelocator_id
                                )
                        )
                    }

                    this.processApiResults(favorites, viewed)

                    this.$nextTick(() => {
                        this.scrollToTop()
                        this.$nextTick(() => {
                            this.scrollToTop()
                        })

                        if (this.isMapShow) {
                            this.addMarkers(true)
                            this.getFavorites()
                        }
                    })
                    --this.isLoading
                })
        },
        addMarkers(removeMarkersLayer) {
            ++this.isLoading
            var markers = new MarkerClusterGroup({
                disableClusteringAtZoom: 10,
                maxClusterRadius: 40
            })
            if (removeMarkersLayer && this.markersLayer) {
                this.map.removeLayer(this.markersLayer)
            }
            this.map.removeLayer(markers)
            if (this.filteredPharmacies.length < this.$options.pharmacyData) {
                this.pharmacies = this.filteredPharmacies
            } else {
                this.pharmacies = this.$options.pharmacyData
            }

            var mapMarker = L.icon({
                iconUrl: getSkinUrl('images/general/marker.svg'),
                iconSize: [25, 41],
                iconAnchor: [12, 41]
            })

            for (let i = 0; i < this.pharmacies?.length; i++) {
                const pharmacy = this.pharmacies[i]
                if (Math.abs(pharmacy.latitude < 1)) {
                    return
                }
                const marker = L.marker(
                    [pharmacy.latitude, pharmacy.longitude],
                    { title: pharmacy.label, icon: mapMarker }
                ).on(
                    'click',
                    function () {
                        this.markerTooltipPharmacy = null
                        this.map.flyTo(
                            [pharmacy.latitude, pharmacy.longitude],
                            15
                        )
                        setTimeout(() => {
                            this.markerTooltipPharmacy = pharmacy
                        }, 750)
                    },
                    this
                )
                this.$options.markers.push(marker)
                markers.addLayer(marker)
            }
            this.markersLayer = markers
            this.map.addLayer(this.markersLayer)
            --this.isLoading
        },
        closePharmacyInfoTooltip() {
            this.markerTooltipPharmacy = null
        },
        SelectPharmacy(el, event) {
            event?.preventDefault()
            this.modalVisible = true
            this.$nextTick(() => this.$refs.modal.OpenModal())
        },
        getUserPharmacy() {
            let userPharmacy = Cookies.get('userPharmacy')
            if (userPharmacy) {
                this.userPharmacy = JSON.parse(userPharmacy)
                for (const [key, value] of Object.entries(this.userPharmacy)) {
                    this.userPharmacy[key] = escape(value)
                }

                if (this.userPharmacy.distance) {
                    this.userPharmacy.distance = parseFloat(
                        this.userPharmacy.distance
                    )
                }

                if (this.userPharmacy) {
                    if (!this.chooseModeChanged) {
                        this.chooseMode = false
                    }
                    axios
                        .get(
                            BASE_URL +
                                'storelocator/ajax/checkIfActive/?pharmacyId=' +
                                this.userPharmacy.key
                        )
                        .then((response) => {
                            if (response.active) {
                                this.showInactivePopup()
                            }
                        })
                }
            }
        },
        loadMap() {
            ++this.isLoading
            const mapWrapper = document.querySelector('.pharmacy-map__wrapper')
            mapWrapper.classList.add('is-loaded')
            this.initMap()
        },
        focusOnMap() {
            if (document.body.clientWidth <= 480) {
                setTimeout(() => {
                    this.$refs.map.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    })
                }, 30)
            }
        },
        ChoosePharmacy(pharmacyData) {
            let cookieHistoric = []

            if (Cookies.get(COOKIE_HISTORIC)) {
                cookieHistoric = JSON.parse(Cookies.get(COOKIE_HISTORIC))
            }

            let name = pharmacyAddressOnlyForSuggester(pharmacyData)

            if (parseInt(cookieHistoric.length) >= 8) {
                cookieHistoric = cookieHistoric.slice(
                    cookieHistoric.length - 7,
                    cookieHistoric.length
                )
            }
            cookieHistoric.push({
                name: name,
                lat: pharmacyData.latitude,
                lon: pharmacyData.longitude,
                type: 1,
                city: pharmacyData.city,
                osmType: null,
                boundingbox: null
            })

            var now = new Date()
            var expireYears = 10
            var expireDate = new Date(
                now.getFullYear() + expireYears,
                now.getMonth(),
                now.getDate()
            )

            Cookies.set(COOKIE_HISTORIC, JSON.stringify(cookieHistoric), {
                expires: expireDate
            })

            const options = {
                storelocator_id: pharmacyData.storelocator_id,
                set_as_default: false,
                check_products: false,
                currentLocation: this.currentLocation
            }
            ++this.isLoading
            this.setPharmacy(options)
                .then((response) => {
                    this.updatePharmacyInfo()
                    if (!Cookies.get('userPharmacy')) {
                        this.cookieError = true
                        return
                    }

                    EventBus.$emit(
                        PHARMACY_UPDATED,
                        JSON.parse(Cookies.get('userPharmacy'))
                    )

                    if (
                        window?.GoogleAnalyticsUniversal?.prototype
                            ?.pharmacyEvent
                    ) {
                        window.GoogleAnalyticsUniversal.prototype.pharmacyEvent(
                            'Pharmacy chosen',
                            pharmacyData.warehouse_id
                        )
                    }

                    if (
                        response.data.error === true &&
                        response.data.redirect
                    ) {
                        location.href = response.data.redirect
                    }

                    if (!Cookies.get('pharmacySelected')) {
                        Cookies.set('pharmacySelected', '')
                    }

                    window.location.reload()
                })
                .catch(() => --this.isLoading)
        },
        getFormKey() {
            return (
                document.querySelector('input[name="form_key"]')?.value || null
            )
        },
        setPharmacy(options) {
            const form_key = this.getFormKey()
            return axios.post(
                BASE_URL + 'storelocator/ajax/selectPharmacy',
                Object.assign(options, { form_key })
            )
        },
        isPharmacyActive(pharmacy) {
            axios
                .get(`${BASE_URL}checkIfActive/?pharmacyId=${pharmacy.key}`)
                .then((response) => {
                    return response.data.isActive
                })
                .catch(() => {
                    return false
                })
        },
        updatePharmacyInfo() {
            const selectPharmacyPins = document.querySelectorAll(
                '.select-pharmacy-pin, .product-shop__pharmacy'
            )

            if (this.userPharmacy && selectPharmacyPins) {
                Array.from(selectPharmacyPins).forEach((el) => {
                    el.querySelector(
                        '.select-pharmacy-text, .product-choose-pharmacy'
                    ).innerHTML = `<span>APTEKA</span><br/><strong>ul. ${this.userPharmacy.street} ${this.userPharmacy.street_no}</strong>`
                })
            }

            Array.from(selectPharmacyPins).forEach(function (el) {
                el.querySelector('span').style.opacity = '1'
            })
        },
        setCurrentPosition() {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    const location = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                    setTimeout(() => {
                        this.currentLocation = location
                    }, 0)
                },
                (error) => {
                    this.geolocationManual = false
                    console.log(error)
                }
            )
        },
        geolocate(manual) {
            // znajdz najblizsza

            if (this.currentLocation && this.geolocationManual) {
                return
            }

            if (!this.map) {
                this.switchToMap()
                setTimeout(() => {
                    this.getGeolocate(manual)
                }, 450)
            } else {
                this.getGeolocate(manual)
            }
        },
        getGeolocate(manual) {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    const location = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                    ++this.isLoading
                    if (manual && this.map !== null) {
                        // this.geolocationManual = true
                        this.getGeolocalize(
                            location.lat,
                            location.lng,
                            1,
                            true,
                            true
                        )

                        this.map.flyTo(location, 13)
                        this.addRedMarker(
                            location.lat,
                            location.lng,
                            'Twoja lokalizacja'
                        )
                    }
                    --this.isLoading
                    setTimeout(() => {
                        this.currentLocation = location
                    }, 0)
                },
                (error) => {
                    this.geolocationManual = false
                    this.isLoading = 0
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                        case error.POSITION_UNAVAILABLE:
                            alert(
                                'Przepraszamy, nie możemy uzyskać Twojej aktualnej lokalizacji, ponieważ ustawienia Twojej przeglądarki na to nie pozwalają. Jeżeli chcesz znaleźć najbliższą aptekę, zezwól na pobieranie lokalizacji i spróbuj ponownie.'
                            )
                            break
                        case error.UNKNOWN_ERROR:
                            alert(
                                'Przepraszamy, pobranie Twojej lokalizacji jest niemożliwe.'
                            )
                            break
                    }
                }
            )
        },
        ShowInactivePopup() {
            window.$apteline.modal.OpenModal({
                title: 'Wybrana przez Ciebie apteka jest chwilowo niedostępna.',
                content:
                    'Prosimy, wybierz inną aptekę dostępną w Twojej okolicy.',
                buttons: [
                    {
                        title: 'Wybierz aptekę',
                        primary: true,
                        action: () => {
                            this.SelectPharmacy()
                        }
                    }
                ]
            })
            if (window?.GoogleAnalyticsUniversal) {
                window.GoogleAnalyticsUniversal.prototype.pharmacyEvent(
                    'Pharmacy blocked',
                    this.userPharmacy.warehouseId
                )
            }
        },
        pharmacyAddressOnly(address) {
            return pharmacyAddressOnly(address)
        },
        pharmacyAddressOnlyForSuggester(address) {
            return pharmacyAddressOnlyForSuggester(address)
        },
        getCustomerData() {
            getCustomer().then((response) => {
                EventBus.$emit(CUSTOMER_DATA_UPDATED, response.data)
                this.customer = response.data
            })
        },
        refreshFavoritesAfterLogin() {
            this.loggedin = true
            this.getFavorites()
        },
        checkIsMobile() {
            return window.innerWidth <= 991
        }
    }
}
</script>
<style>
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
</style>
<style lang="scss">
.modalPharmacyMap {
    @media #{$media-to-sm} {
        background-color: rgba(255, 255, 255, 0.96) !important;
    }

    .custom-modal--bordered {
        @media #{$media-xs} {
            overflow: unset;
        }

        .custom-modal-close {
            @media #{$media-xs} {
                top: -20px;
                right: -20px;
            }
        }
    }
    &.custom-modal-backdrop {
        z-index: 21474836401;
        bottom: auto !important;
        min-height: 100%;
        max-height: 100%;
    }
    .custom-modal-close {
        @media #{$media-to-sm} {
            top: -15px;
            right: -15px;
            padding: 4px !important;
            min-width: 32px !important;
            min-height: 32px !important;
            width: 32px !important;
            height: 32px !important;
            z-index: 1000;
            position: absolute;
        }
    }

    .custom-modal {
        @media #{$media-to-sm} {
            width: 92vw;
            height: 80vh;
            min-height: 80vh !important;
            min-height: 80dvh !important;
            border-radius: 12px;
            position: relative;
            overflow: visible;

            @media screen and (max-height: 799px) {
                min-height: 80vh !important;
                min-height: 90dvh !important;
            }
        }

        .custom-modal-wrapper {
            @media #{$media-to-sm} {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        section:first-of-type {
            @media #{$media-to-sm} {
                margin-top: 0px;
                margin-bottom: 0px;
                margin-left: 0px;
                margin-right: 0px;
                padding-left: 0px;
                padding-right: 0px;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    &--filters-act {
        @media #{$media-to-sm} {
            .custom-modal-close {
                display: none;
            }

            .pharmacy-map__col {
                background: none;
                border: none;
                box-shadow: none;

                &:after {
                    display: none;
                }
            }

            .pharmacy-map__header {
                display: none;
            }
        }
    }
}
.pharmacy-map {
    margin: -45px -25px -45px -25px;
    border-radius: 8px;
    overflow: hidden;

    @media #{$media-to-sm} {
        height: 100%;
        min-height: 380px;
        border-radius: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__form {
        position: relative;
    }

    &__choose {
        @media #{$media-xs} {
            padding-right: 10px;
        }
        z-index: 450;
    }
    &__choose h2 {
        color: $sherpa-blue;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 46px;
        margin-bottom: 8px;
    }
    &__choose button {
        right: 0;
    }
    &__col {
        position: absolute;
        z-index: 999;
        background: #fff;
        border-radius: 8px;
        border: 1px solid rgba(183, 189, 196, 0.5);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        width: 294px;
        min-width: 294px;
        top: 0;
        padding-top: 15px;
        padding-left: 9px;
        padding-right: 9px;

        &--choose {
            bottom: 0px; //orginalnie 40px
            padding-bottom: 15px;
        }

        &--res {
            height: calc(100% - 75px);

            &::after {
                content: '';
                display: block;
                position: absolute;
                background: linear-gradient(
                    180deg,
                    hsla(0, 0%, 100%, 0) 0,
                    #fff 100%,
                    #fff
                );
                left: 0;
                width: 100%;
                height: 40px;
                bottom: 0;
            }
        }

        @media #{$media-to-sm} {
            padding: 10px 15px 0 15px;
            width: 100%;
            min-height: 134px;
            bottom: unset;
        }
        @media #{$media-to-sm, $media-sm} {
            bottom: unset;
        }
        @media #{$media-xs} {
            bottom: unset;
        }
    }

    &__list-container {
        position: absolute;
        top: 175px;
        bottom: 0;
        left: 15px;
        right: 15px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #d9d9d9;
        }

        &::-webkit-scrollbar-thumb {
            background: #898686;
        }
    }

    &__list {
        position: relative;
        display: block;
        height: auto;
        margin-top: 20px;
        width: 100%;

        h3 {
            max-width: 155px;
        }

        &-item:first-of-type {
            .pharmacy-info {
                @media #{$media-to-sm} {
                    padding-top: 10px;
                }

                &__action {
                    @media #{$media-to-sm} {
                        top: 6px;
                    }
                }
            }
        }

        .pharmacy-info {
            @media #{$media-to-sm} {
                padding-top: 20px;
                padding-bottom: 10px;
            }

            &__action {
                @media #{$media-to-sm} {
                    padding-top: 10px;
                    top: 16px;
                }
            }

            &__like {
                bottom: 12px;
            }
        }
    }
    &__header {
        margin-bottom: 10px;

        h4 {
            color: $sherpa-blue;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 0px;
            line-height: 26px;

            small {
                display: block;
                color: $gray-darker3;
                font-size: 13px;
                margin-top: 4px;
            }
        }
    }

    &__text {
        display: none;
        @media #{$media-xs} {
            display: block;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 5px;
        }
    }

    &__chosen-pharmacy {
        width: 100%;
        padding-top: 7px;
        padding-bottom: 25px;
        margin-bottom: 15px;
        position: relative;

        h4 {
            color: $sherpa-blue;
        }

        &-close {
            position: absolute;
            top: -27px;
            right: -25px;
            width: 40px;
            height: 38px;

            @media #{$media-to-sm} {
                display: none;
            }
        }

        &-btn {
            float: right;
            text-transform: none;
            font-size: 14px;
            font-weight: 400;
            min-height: auto;
            border-radius: 8px;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 5px;
            padding-bottom: 4px;
        }

        .pharmacy-info {
            border-bottom: none;
            padding-bottom: 17px;
        }

        .pharmacy-info__address {
            font-size: 13px;
            font-weight: 400;

            span {
                h3 {
                    font-size: 14px;
                    font-weight: 600;
                    color: $sherpa-blue;
                    padding-top: 11px;
                }

                h4 {
                    font-size: 13px !important;
                    font-weight: 600;
                    margin: 0;
                    color: $sherpa-blue;
                    line-height: 26px;
                }
            }
        }

        .pharmacy-info__like {
            display: none;
        }
    }

    &-col-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        &__title {
            color: $sherpa-blue;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__btn {
            width: 145px;
            min-height: auto;
            background: $sherpa-blue;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #fff !important;
            text-transform: none;
            padding-top: 4px;
            padding-bottom: 4px;
            border-radius: 8px;

            @media #{$media-to-sm} {
                margin-right: 15px;
            }
        }
    }

    &__search {
        p {
            margin: 10.5px 0;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            color: $sherpa-blue;
        }
    }

    &__geolocate {
        height: 40px;
        width: 100%;

        svg {
            margin-right: 7px;
        }
        svg path {
            fill: #fff;
        }

        &:hover,
        &:focus {
            background: #fff;
            svg path {
                fill: $sherpa-blue;
            }
        }

        &--disabled {
            cursor: not-allowed;
        }
    }

    &__label {
        display: block;
        margin: 15px 0;
        font-size: 15px;

        &:first-of-type {
            @media #{$media-xs} {
                margin-top: 10px;
            }
        }
    }

    &__select {
        cursor: pointer;
        font-style: normal;
        margin-top: 6px;

        &--capitalize {
            text-transform: capitalize;
        }
    }

    &__selected {
        display: block;
        margin: 30px 0;
    }

    &__new {
        max-width: 240px;
        margin: 0 auto;

        h4 {
            font-size: 24px;
            color: $sherpa-blue;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        h3 {
            margin-bottom: 4px;
        }

        .icon-checked {
            color: $sherpa-blue;
            margin: 0 auto 20px;
            display: block;
            text-align: center;
        }
    }

    &__placeholder {
        position: relative;

        .pharmacy-map__switch-to-map {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 24px;
            text-transform: none;
            line-height: 1;

            @media #{$media-to-sm} {
                margin-left: 0px !important;
                transform: translate(-50%, calc(-50% + 120px));

                &--list-act {
                    transform: translate(-50%, calc(-50% + 245px));
                }
            }
        }

        img {
            opacity: 0.5;
        }
    }

    .loader-dots__container--full {
        z-index: 9999999;

        @media #{$media-to-sm} {
            position: fixed;
        }
    }
    #map,
    #offlineMap {
        height: 873px;
        max-height: 1000px;
        min-width: 334px;
        width: 100%;

        @media #{$media-to-sm} {
            height: 100%;
            min-height: 380px;
            min-width: 100%;
        }
    }
    &__wrapper {
        padding-left: 0;
        padding-right: 0;
        @media #{$media-to-sm} {
            height: 100%;
            min-height: 380px;
        }

        #map,
        #offlineMap {
            @media #{$media-lg} {
                max-height: 1000px;
            }
            @media #{$media-md} {
                max-height: 800px;
            }
            @media #{$media-to-sm, $media-sm} {
                max-height: 475px;
                margin-top: 320px;
            }
            @media #{$media-xs} {
                max-height: 100%;
                margin-top: 0px;
            }
        }

        &.is-loaded {
            #map {
                display: block;
            }

            .pharmacy-map__placeholder {
                display: none;
            }
        }
    }

    &__placeholder {
        height: 595px;
        width: 100%;
        background-size: cover;
        background-position: 50%;

        @media #{$media-to-sm} {
            height: 400px;
        }
    }

    .btn,
    button {
        min-width: 30px;
    }
    input:active:enabled,
    input:focus:enabled {
        border-width: 1px !important;
    }
    .input-group {
        &::before {
            position: absolute;
            display: block;
            z-index: 99;
            content: attr(data-placeholder);
            font-size: 13px;
            color: #888;
            pointer-events: none;
            line-height: 1;
            width: 100%;
            padding-left: 10px;
            padding-right: 55px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .input-group input {
        z-index: 98;
        position: relative;
        min-height: 40px;
        height: 40px;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: transparent;
    }
    .input-group input:active,
    .input-group input:focus,
    .input-group input:not(:placeholder-shown) {
        z-index: 100;
        background: #fff;
        border-width: 1px;
        box-shadow: none;
    }
    .input-group button {
        min-height: 40px;
        height: 40px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-top: 15px;
        background-color: $red-btn;
        border-color: $red-btn;

        &:hover {
            background-color: $red-btn;
            border-color: $red-btn;
        }
    }
    .input-group.rollout input {
        border-bottom-left-radius: 0;
    }
    .input-group.rollout button {
        border-bottom-right-radius: 0;
    }
    input::placeholder {
        font-size: 12px;
        font-weight: normal;
        opacity: 0.5;
        color: #333;
    }
}

.custom-modal-close {
    width: 48px !important;
    height: 39px !important;
}
.suggester {
    &-results {
        z-index: 451;
        position: absolute;
        width: 100%;
        background: #fff;
    }
    &__list {
        border: 1px solid #ddd;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: +1;
        li {
            padding: 4px;
            padding-top: 8px;
            padding-bottom: 8px;
            display: table;
            cursor: pointer;
            svg {
                margin: 4px;
                margin-left: 0px;
                margin-right: 8px;
                vertical-align: middle;
                display: table-cell;

                path {
                    color: $sherpa-blue;
                    background-color: $sherpa-blue;
                    fill: $sherpa-blue;
                }
            }
            a {
                color: #666;
                left: 22px;
                width: 100%;
                vertical-align: middle;
                display: table-cell;
                font-size: 15px;
                font-weight: 400;

                strong {
                    font-weight: 800;
                    color: #333;
                }

                span {
                    color: $sherpa-blue;
                    font-weight: 600;
                }
            }
            a:hover {
                color: #333;
            }
        }
    }
}
#map {
    display: block;
    width: 100%;
    max-height: 595px;
    height: 100%;

    @media #{$media-to-sm} {
        height: 400px;
        max-height: 400px;
    }
}
.leaflet-control-container {
    .leaflet-top.leaflet-left {
        display: none;
    }
    .leaflet-bottom.leaflet-right {
        @media #{$media-to-sm} {
            bottom: 15px;
        }
    }
}

.leaflet-popup-content {
    text-align: center;
    a {
        display: inline-block;
        font-weight: 600;
        margin-bottom: 4px;
    }
}
.more {
    position: relative;
}
#pharmacyTooltipDiv {
    width: 450px;
    position: absolute;
    top: calc(50% + 10px);
    left: calc(50% - 225px);
    z-index: 1000;
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 8px;
    padding: 12px;

    @media #{$media-md} {
        width: 350px;
        left: calc(50% - 175px);
    }
    @media #{$media-to-sm, $media-sm} {
        top: calc(50% + 155px);
    }
    @media #{$media-xs} {
        width: 300px;
        top: calc(50% + 85px);
        left: calc(50% - 150px);
    }

    > button {
        background: none;
        display: block;
        border: 0px;
        margin: 0px;
        padding: 0px;
        text-transform: none;
        appearance: none;
        position: absolute;
        cursor: pointer;
        user-select: none;
        top: -6px;
        right: -6px;
        width: 30px;
        height: 30px;
    }
}

.btn--pharmacy-map-list-act {
    @media #{$media-to-sm} {
        margin-top: 30px;
    }
}
</style>
