var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testo" },
    [
      _c("Modal", {
        ref: "loginmodal",
        staticClass: "pharmacyMapModal",
        attrs: { theme: "bordered" },
        on: { "before-enter": _vm.modalBeforeEnter },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _vm.favorite && _vm.loggedin
                  ? _c(
                      "div",
                      {
                        staticClass: "pharmacy-map__new",
                        on: { click: _vm.close }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "60",
                              height: "60",
                              viewBox: "0 0 60 60",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: { "clip-path": "url(#clip0_3081_723)" }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 6.51855C42.9684 6.51855 53.4778 17.0348 53.4778 30C53.4778 42.9653 42.9684 53.4778 30 53.4778C17.0315 53.4778 6.5222 42.9653 6.5222 30C6.52225 17.0348 17.0315 6.51855 30 6.51855ZM41.4953 15.7544L24.1077 33.1457L18.468 27.5061L12.9346 33.0359L18.5742 38.6755L24.1443 44.2456L29.6741 38.7122L47.0654 21.3245L41.4953 15.7544Z",
                                    fill: "#007F32"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("defs", [
                              _c(
                                "clipPath",
                                { attrs: { id: "clip0_3081_723" } },
                                [
                                  _c("rect", {
                                    attrs: {
                                      width: "60",
                                      height: "60",
                                      fill: "white"
                                    }
                                  })
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("h4", [_vm._v("Dodano do ulubionych:")]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.pharmacyAddressOnly(_vm.favorite)
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.loggedin
                  ? _c("div", { attrs: { id: "newPharmacyForm" } }, [
                      _c("div", { staticClass: "row checkout-columns" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "h2",
                              { staticStyle: { "margin-bottom": "15px" } },
                              [
                                _vm._v(
                                  "\n                            Zaloguj się, aby dodać aptekę do ulubionych\n                        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ValidationObserver",
                              {
                                ref: "login",
                                attrs: { tag: "form" },
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.submit()
                                  }
                                }
                              },
                              [
                                _vm.loginError
                                  ? _c("div", { staticClass: "login-error" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.loginError.message) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "checkout-login__form checkout-form form"
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      staticClass: "form__row",
                                      attrs: {
                                        mode: "eager",
                                        rules: "required-email|email",
                                        tag: "div",
                                        vid: "f3_email"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.trim",
                                                      value:
                                                        _vm.customerData.login
                                                          .email,
                                                      expression:
                                                        "\n                                            customerData.login.email\n                                        ",
                                                      modifiers: { trim: true }
                                                    }
                                                  ],
                                                  class: {
                                                    "is-failed": errors[0]
                                                  },
                                                  attrs: {
                                                    id: "login.email",
                                                    type: "email",
                                                    name: "login.email",
                                                    autocomplete: "email",
                                                    "aria-required": "true",
                                                    placeholder:
                                                      "Tu wpisz swój email"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.customerData.login
                                                        .email
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.customerData.login,
                                                        "email",
                                                        $event.target.value.trim()
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.$forceUpdate()
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                errors.length
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "validation-advice",
                                                        attrs: { role: "alert" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(errors[0]) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1449919856
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("ValidationProvider", {
                                      staticClass: "form__row",
                                      attrs: {
                                        mode: "eager",
                                        rules: "required",
                                        tag: "div",
                                        vid: "f4_password"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.customerData.login
                                                          .customer_password,
                                                      expression:
                                                        "\n                                            customerData.login\n                                                .customer_password\n                                        "
                                                    }
                                                  ],
                                                  class: {
                                                    "is-failed": errors[0]
                                                  },
                                                  attrs: {
                                                    id:
                                                      "login.customer_password",
                                                    type: "password",
                                                    "aria-required": "true",
                                                    autocomplete:
                                                      "new-password",
                                                    name:
                                                      "login.customer_password",
                                                    placeholder:
                                                      "Tu wpisz swoje hasło."
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.customerData.login
                                                        .customer_password
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.customerData.login,
                                                        "customer_password",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                errors.length
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "validation-advice",
                                                        attrs: { role: "alert" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(errors[0]) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1293484415
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "checkout-actions" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn--bg btn--loader btn--mobile",
                                      attrs: { type: "submit" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Zaloguj się\n                                "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "login-link",
                          on: { click: _vm.redirectToRegister }
                        },
                        [
                          _vm._v(
                            "\n                    Nie masz konta? Zarejestruj się.\n                "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }