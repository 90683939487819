<template>
    <div v-if="pharmacyData" class="pharmacy-info">
        <div v-if="allowSelect" class="pharmacy-info__action">
            <button
                ref="chooseButton"
                class="btn--bg"
                tabindex="0"
                @click.prevent="choosePharmacy(pharmacyData)"
            >
                Wybierz >>
            </button>
        </div>
        <div class="pharmacy-info__address">
            <span v-html="formatAddress" />
            <div
                v-if="pharmacyData.distance > 0 && searchResultsType != 'city'"
            >
                <div class="pharmacy-info__distance">
                    <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.51943 3.57333L11.1926 0.864582C11.246 0.849635 11.3024 0.849181 11.3561 0.863268C11.4098 0.877355 11.4587 0.905473 11.4979 0.944736C11.5371 0.983998 11.5651 1.03299 11.5791 1.08667C11.5931 1.14036 11.5926 1.1968 11.5776 1.25021L8.86943 10.9227C8.85221 10.9849 8.81615 11.0403 8.7662 11.0812C8.71625 11.1221 8.65486 11.1465 8.59046 11.1511C8.52607 11.1557 8.46184 11.1402 8.40659 11.1068C8.35134 11.0734 8.3078 11.0237 8.28193 10.9646L6.25193 6.35083C6.22058 6.27915 6.16349 6.22183 6.09193 6.19021L1.47818 4.16021C1.41902 4.1344 1.36931 4.0909 1.33586 4.03569C1.30242 3.98049 1.2869 3.91628 1.29142 3.8519C1.29594 3.78751 1.32028 3.7261 1.36111 3.67611C1.40194 3.62612 1.45724 3.59062 1.51943 3.57333Z"
                            stroke="#007F32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ pharmacyData.distance.toFixed(2) }}
                    km od
                    {{
                        searchResultsType == 'citystreet'
                            ? 'Wybranej'
                            : 'Twojej'
                    }}
                    lokalizacji
                </div>
            </div>
        </div>

        <div class="pharmacy-info__like">
            <span
                v-if="allowLike"
                ref="unlikeButton"
                class="liked"
                tabindex="0"
                @click.prevent="removeFavorite(pharmacyData)"
            >
                <svg
                    width="15"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                >
                    <g>
                        <path
                            id="svg_2"
                            stroke="null"
                            d="m3.59277,2.54171l2.43426,0.37552l1.32477,1.04493l2.91449,-1.69801l2.38458,0.65308l1.05982,2.87356l-1.05982,1.43678l-0.92734,2.48171c0.0828,0.01633 -4.02399,2.75927 -3.89151,2.75927c0.13248,0 -5.56403,-3.39603 -5.56403,-3.65726c0,-0.26123 -0.92734,-4.44096 -1.01014,-4.45728c0.0828,0.01633 2.33491,-1.8123 2.33491,-1.8123z"
                            opacity="NaN"
                            fill="#007F32"
                        />
                        <path
                            id="svg_1"
                            fill="#007F32"
                            d="m10.4297,1.64062c-1.17716,0 -2.22189,0.46465 -2.9297,1.26329c-0.70781,-0.79864 -1.75254,-1.26329 -2.92969,-1.26329c-1.02526,0.00125 -2.00817,0.40908 -2.73314,1.13405c-0.72497,0.72497 -1.13281,1.70788 -1.13404,2.73314c0,4.23867 6.1998,7.62539 6.46347,7.76779c0.10246,0.0552 0.21702,0.0841 0.3334,0.0841c0.11638,0 0.23094,-0.0289 0.3334,-0.0841c0.26367,-0.1424 6.4635,-3.52912 6.4635,-7.76779c-0.0013,-1.02526 -0.4091,-2.00817 -1.1341,-2.73314c-0.7249,-0.72497 -1.7079,-1.1328 -2.7331,-1.13405zm-0.3217,8.34141c-0.81605,0.69247 -1.6884,1.31577 -2.608,1.86327c-0.9196,-0.5475 -1.79195,-1.1708 -2.60801,-1.86327c-1.26972,-1.08926 -2.78261,-2.75039 -2.78261,-4.47422c0,-0.65268 0.25927,-1.27863 0.72079,-1.74014c0.46151,-0.46152 1.08746,-0.7208 1.74014,-0.7208c1.04297,0 1.91602,0.55079 2.27871,1.4379c0.05279,0.12932 0.1429,0.23998 0.25884,0.31788c0.11594,0.0779 0.25246,0.11951 0.39214,0.11951c0.13968,0 0.2762,-0.04161 0.39214,-0.11951c0.11594,-0.0779 0.20605,-0.18856 0.25884,-0.31788c0.36269,-0.88711 1.23574,-1.4379 2.27872,-1.4379c0.6527,0 1.2786,0.25928 1.7401,0.7208c0.4615,0.46151 0.7208,1.08746 0.7208,1.74014c0,1.72383 -1.5129,3.38496 -2.7826,4.47422z"
                        />
                    </g>
                </svg>
            </span>
            <span
                v-else
                ref="likeButton"
                class="unliked"
                tabindex="0"
                @click.prevent="setFavorite(pharmacyData)"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                >
                    <path
                        d="M10.4297 1.64062C9.25254 1.64062 8.20781 2.10527 7.5 2.90391C6.79219 2.10527 5.74746 1.64062 4.57031 1.64062C3.54505 1.64187 2.56214 2.0497 1.83717 2.77467C1.1122 3.49964 0.704365 4.48255 0.703125 5.50781C0.703125 9.74648 6.90293 13.1332 7.1666 13.2756C7.26906 13.3308 7.38362 13.3597 7.5 13.3597C7.61638 13.3597 7.73094 13.3308 7.8334 13.2756C8.09707 13.1332 14.2969 9.74648 14.2969 5.50781C14.2956 4.48255 13.8878 3.49964 13.1628 2.77467C12.4379 2.0497 11.4549 1.64187 10.4297 1.64062ZM10.108 9.98203C9.29195 10.6745 8.4196 11.2978 7.5 11.8453C6.5804 11.2978 5.70805 10.6745 4.89199 9.98203C3.62227 8.89277 2.10938 7.23164 2.10938 5.50781C2.10938 4.85513 2.36865 4.22918 2.83017 3.76767C3.29168 3.30615 3.91763 3.04687 4.57031 3.04687C5.61328 3.04687 6.48633 3.59766 6.84902 4.48477C6.90181 4.61409 6.99192 4.72475 7.10786 4.80265C7.2238 4.88055 7.36032 4.92216 7.5 4.92216C7.63968 4.92216 7.7762 4.88055 7.89214 4.80265C8.00808 4.72475 8.09819 4.61409 8.15098 4.48477C8.51367 3.59766 9.38672 3.04687 10.4297 3.04687C11.0824 3.04687 11.7083 3.30615 12.1698 3.76767C12.6313 4.22918 12.8906 4.85513 12.8906 5.50781C12.8906 7.23164 11.3777 8.89277 10.108 9.98203Z"
                        fill="#264653"
                    />
                </svg>
            </span>
        </div>
    </div>
</template>

<script>
import { pharmacyAddress } from './addressFormatter'

export default {
    props: {
        pharmacyData: {
            type: Object,
            default: () => {}
        },
        currentLocation: {
            type: Object,
            default: () => {}
        },
        searchResultsType: {
            type: Object,
            default: () => {}
        },
        allowSelect: {
            type: Boolean,
            default: false
        },
        allowLike: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        formatAddress() {
            return pharmacyAddress(this.pharmacyData)
        }
    },
    methods: {
        choosePharmacy(pharmacyData) {
            window.$apteline.pharmacy.ChoosePharmacy(pharmacyData)
        },
        setFavorite(pharmacyData) {
            this.$parent.$parent.setFavorite(pharmacyData)
        },
        removeFavorite(pharmacyData) {
            this.$parent.$parent.removeFavorite(pharmacyData)
        }
    }
}
</script>

<style lang="scss">
$distanceCircleWidth: 10px !default;
$distanceCirclesMargin: 12px !default;

.pharmacy-info {
    color: $blue-dianne;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #ddd;
    position: relative;

    h3 {
        margin: 0 0 8px;
        font-size: 14px;
        color: $sherpa-blue;
    }
    h4 {
        margin: 4px 0 4px;
        font-size: 13px;
        color: $sherpa-blue;
    }
    &__address {
        flex-grow: 1;
        margin: 6px auto 4px;
        font-size: 13px;

        h4 {
            font-size: 13px !important;
        }

        span {
            h3 {
                font-size: 14px;
                line-height: 14px;
            }
        }
    }

    &__nav {
        position: relative;
        padding-left: $distanceCirclesMargin;
        color: $gray-dark;

        @media #{$media-xs} {
            display: none;
        }

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 60%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $gray-dark;
        }
    }

    &__distance {
        font-weight: 700;
        color: inherit;
        position: relative;
        padding-top: 5px;
    }
    &__like {
        position: absolute;
        right: 13px;
        bottom: 2px;
        span {
            cursor: pointer;
        }
    }

    &__navigate {
        color: inherit;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(
                #{-$distanceCirclesMargin} - #{$distanceCircleWidth / 2}
            );

            @include n-theme() {
                border: 1px solid theme(color-primary);
                background-color: theme(color-primary);
            }
        }
    }

    &__action {
        position: absolute;
        top: 6px;
        right: 8px;

        @media #{$media-to-sm} {
            top: 26px;
        }
        .btn--bg {
            font-size: 11px;
            font-weight: normal;
            text-transform: none;
            min-height: 21px;
            height: 21px;
            padding: 0px;
            padding-left: 6px;
            padding-right: 6px;
        }
    }
}
.custom-modal button:focus {
    outline-style: none !important;
}
</style>
