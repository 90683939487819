<template functional>
    <div
        v-show="props.visible"
        class="loader-dots__container"
        :class="{ 'loader-dots__container--full': props.fullContainer }"
    >
        <div class="loader-dots">
            <div class="dot dot1"></div>
            <div class="dot dot2"></div>
            <div class="dot dot3"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        fullContainer: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.loader-dots__container {
    background-color: rgba(0, 0, 0, 0.2);
}
</style>
