var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedPharmacy
    ? _c("Modal", {
        ref: "modal",
        attrs: { size: "short" },
        on: {
          leave: _vm.modalClosed,
          click: function($event) {
            $event.preventDefault()
            return _vm.close.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "pharmacy-map__new",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.close.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "60",
                            height: "60",
                            viewBox: "0 0 60 60",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c(
                            "g",
                            { attrs: { "clip-path": "url(#clip0_3081_723)" } },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0ZM30 6.51855C42.9684 6.51855 53.4778 17.0348 53.4778 30C53.4778 42.9653 42.9684 53.4778 30 53.4778C17.0315 53.4778 6.5222 42.9653 6.5222 30C6.52225 17.0348 17.0315 6.51855 30 6.51855ZM41.4953 15.7544L24.1077 33.1457L18.468 27.5061L12.9346 33.0359L18.5742 38.6755L24.1443 44.2456L29.6741 38.7122L47.0654 21.3245L41.4953 15.7544Z",
                                  fill: "#007F32"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c("defs", [
                            _c(
                              "clipPath",
                              { attrs: { id: "clip0_3081_723" } },
                              [
                                _c("rect", {
                                  attrs: {
                                    width: "60",
                                    height: "60",
                                    fill: "white"
                                  }
                                })
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [_vm._v("Wybrana apteka:")]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.pharmacyAddressOnly(_vm.selectedPharmacy)
                          )
                        }
                      })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1935326681
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }